import AddConnection from "../../../components/asheetPlugin/add-connections/add-connection";
import ConnectPageView from "../../../components/asheetPlugin/connect-page-view/connect-view";
import ConnectingPageView from "../../../components/asheetPlugin/connecting-page-view/connecting-view";
import SuccessfullConnectedView from "../../../components/asheetPlugin/successfull-connected/successfull-connected";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataSourceType, getDataSourceTypeByID } from "../../../services/data-source-type.service";
import { toast } from "react-toastify";
import { addDataSourceType, deleteDataSource, disconnetCloudConnection, getDataSourceByUID, getDataSources, getTemplateDataSourceByID, updateDataSourceType } from "../../../services/data-source.service";
import { useASContext } from "../../context-api/as-context";
import { RestServiceConstants } from "../../../constants/rest-service-constant";
import { generateCode, generateRandomString } from "../../../shared/code-challanges/code-challanges";
import { getTokenFromAuthCode } from "../../../services/authCallback-service";
import ConfirmationModel from "../../../components/asheetPlugin/confirmation-model/confirmation-model";
import { AddConnectionComponents, ComponentRendering } from "../../../constants/asheet-plugin-constant";
import serverFunctions from "../../../components/asheetPlugin/utils2/serverFunctions";
import { getASTenantId, getRefreshToken, getToken } from "../../../shared/local-storage-handler/local-storage-handler";

const AddConnectionContoller = () => {
    const navigate = useNavigate();
    const asContext = useASContext();
    const windowRef = useRef();

    const [connectionTypes, setConnectionTypes] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [showConfirmationModel, setshowConfirmationModel] = useState(false);
    const [selectedConnection, setSelectedConnection] = useState();
    const [usedCloudApplicationsId, setUsedCloudApplicationsId] = useState(new Set());
    const [connections, setConnections] = useState();
    const [connection, setConnection] = useState();
    const [connectionUId, setConnectionUId] = useState();
    const [stateConnectionParameter, setStateConnectionParameter] = useState();
    const [plugInAnchorEl, setPlugInAnchorEl] = useState();
    const [renderComponent, setRenderComponent] = useState();
    const [openedIFrame, setOpenedIFrame] = useState(false);
   
    useEffect(() => {
        renderComponents(AddConnectionComponents?.ADD_CONNECTION);
    }, [])

    useEffect(() => {
        setRenderComponent(asContext?.asheetPlugin?.renderComponent);
    }, [asContext?.asheetPlugin?.renderComponent])

    const renderComponents = (fieldToShow) => {
        const newState = new ComponentRendering();

        if (newState?.hasOwnProperty(fieldToShow)) {
            newState[fieldToShow] = true;
        }
        asContext?.asheetPlugin?.setRenderComponent(newState);
    };

    useEffect(() => {
        if (renderComponent?.showAddConnection === true) {
            getConnectionTypeList();
            setConnectionUId(null);
            getConnectionsList();
        }
    }, [renderComponent?.showAddConnection])

    const getConnectionTypeList = async () => {
        try {
            const response = await getDataSourceType();
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                const cloudSources = response?.data?.filter(card => card.templateConnectionId !== null);
                const naturalSources = response?.data?.filter(card => card.templateConnectionId === null);
                setConnectionTypes([
                    { list: cloudSources || [], heading: "Cloud Native Sources", showList: true },
                    { list: naturalSources || [], heading: "Natural Sources", showList: false }
                ]);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const getConnectionsList = async () => {
        try {
            const response = await getDataSources();
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else {               
                setConnections(response?.data);
                setUsedCloudApplicationsId(new Set(response?.data?.map(ca => ca.sysConnectionTypeId)));
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const getConnectionUId = (templateConnectionId) => {
        const connection = connections?.find((row) => row?.templateConnectionId === templateConnectionId);
        return connection?.uId;
    }

    const getConnectionByUId = async (uId) => {
        try {
            const response = await getDataSourceByUID(uId);
            if (response?.hasError) {
                toast.error(response?.errorMessage)
            }
            else {
                setConnection(response?.data);
                return response?.data;
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const disconnectConnection = async () => {
        try {
            const response = await disconnetCloudConnection(connectionUId);
            if (!response?.data?.hasError) {
                setshowConfirmationModel(false);
                setConnectionUId(null);
            }
            else {
                toast.error("Couldn't disconnected this connection!.");
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const deleteConnection = async () => {
        try {
            if (showConfirmationModel) {
                const response = await deleteDataSource(connectionUId);
                if (response.hasError) {
                    toast.success("Couldn't delete this connection!.");
                }
                else {
                    getConnectionsList();
                    setConnectionUId(null);
                    setshowConfirmationModel(false);
                }
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const getTemplateDataSourceById = async (id) => {
        try {
            const response = await getTemplateDataSourceByID(id);
            if (response?.hasError) {
            }
            else {
                let parameters = await makeParameters(response?.data);
                setConnection((prevState) => ({
                    ...prevState, parameters: parameters,
                    sysConnectionTypeId: response?.data?.connectionTypeId
                }));                
                return response?.data;
            }
        }
        catch (error) {
            toast.error(error);
        }
    }

    const getConnectionTypesParameters = async (id) => {
        try {
            const response = await getDataSourceTypeByID(id);
            if (response?.hasError) {
                toast.success(response?.errorMessage);
            }
            else {               
                return response?.data;
            }
        }
        catch (error) {
            toast.error(error);
        }
    }

    const getNaturalDataSourceById = async (id) => {
        try {
            const response = await getDataSourceTypeByID(id);
            if (response?.hasError) {
                toast.success(response?.errorMessage);
            }
            else {
                setConnection(response?.data);
            }
        }
        catch (error) {
            toast.error(error);
        }
    }

    const getAccessToken = async () => {
        try {
            const response = await getTokenFromAuthCode(stateConnectionParameter, connection);
            if (response?.data?.hasError) {
                checkIfWindowOpen();
            }
            else {
                if (response?.data?.data?.access_token) {
                    connectionUId ? updateConnection(response?.data?.data) : saveConnection(response?.data?.data);
                }
                else if (response?.data?.data?.access_token) {
                }
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const saveConnectionObject = async (tokenData) => {
        connection?.parameters?.forEach((parameter) => {
            switch (parameter?.parameterName) {
                case RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN:
                    parameter.parameterValue = tokenData?.access_token;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_REFRESHTOKEN:
                    parameter.parameterValue = tokenData?.refresh_token;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN_EXPIRESAT:
                    parameter.parameterValue = tokenData?.expires_in;
                    break;
                default:
                    break;
            }
        });

        const data = { ...connection };

        data["baseConnectionTypeId"] = connection?.sysConnectionTypeId;
        data["sysConnectionTypeId"] = selectedConnection?.id;
        data["templateConnectionId"] = selectedConnection?.templateConnectionId;
        data["description"] = "";
        data["uId"] = connectionUId;
        return data;
    }

    const saveConnection = async (tokenData) => {
        try {
            let data = await saveConnectionObject(tokenData);
            const response = await addDataSourceType(data);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                setConnectionUId(response?.data?.entityUId);
                setOpenPopup(!openPopup);
                renderComponents(AddConnectionComponents?.SUCCESSFULL_CONNECTED);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const updateConnection = async (tokenData) => {
        try {
            let data = await saveConnectionObject(tokenData);
            const response = await updateDataSourceType(data);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                setOpenPopup(!openPopup);
                renderComponents(AddConnectionComponents?.SUCCESSFULL_CONNECTED);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const handleListView = (index) => {
        setConnectionTypes(prevDataSources => {
            return prevDataSources.map((dataSource, idx) =>
                idx === index ? { ...dataSource, showList: !dataSource.showList } : dataSource
            );
        });
    };

    const onSelectConnection = (connection) => {
        setSelectedConnection(connection);
        let uId = getConnectionUId(connection?.templateConnectionId);
        if (uId) {
            setConnectionUId(uId);
            getConnectionByUId(uId);
        }
        else if (connection?.templateConnectionId !== null) {
            getTemplateDataSourceById(connection?.templateConnectionId);
        }
        else {
            getNaturalDataSourceById(connection?.id);
        }
        renderComponents(AddConnectionComponents?.CONNECT_VIEW);
    }    

    const addNativeConnection = async () => {
        const params = {
            connectionId: selectedConnection?.id,
            token: getToken(),
            refreshToken: getRefreshToken(),
            asTenantUid: getASTenantId()
        }
        const url = `connections/add-connection?connectionId=${params?.connectionId}&iFrame=${true}
                                    &AccessToken=${params?.token}&RefreshToken=${params?.refreshToken}
                                    &ASTenantUId=${params?.asTenantUid}&closeWindowOnPopupClose=true`;
        setOpenedIFrame(true);
 
        await serverFunctions.openDialog(url).then().catch(alert);
    };

    const fetchData = async () => {
        const sheetChanges = await serverFunctions?.getSheetChange();
        if (sheetChanges?.addedConnection == "true") {    
            renderComponents(AddConnectionComponents?.SUCCESSFULL_CONNECTED);
            setOpenedIFrame(false);
        }
    }

    useEffect(() => {
        if(openedIFrame){
            const intervalId = setInterval(async () => {
                await fetchData();
            }, 1000);
            return () => clearInterval(intervalId);
        }        
    }, [openedIFrame]);

    const makeParameters = async (connectionParametersData) => {
        try {
            const newParameters = connectionParametersData?.parameters?.map((item) => ({
                configConnectionParameterTypeId: item?.configConnectionParameterTypeId,
                parameterName: item?.parameterName,
                parameterValue: item?.parameterValue,
                isValid: true
            }));

            const authCodeParameter = {
                configConnectionParameterTypeId: 2016,
                parameterName: RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE,
                parameterValue: "",
                isValid: true
            };
            newParameters?.push(authCodeParameter);

            const response = await getConnectionTypesParameters(connectionParametersData?.connectionTypeId);
            if (response?.hasError) {
                return newParameters;
            }
            else {
                response?.parameters?.forEach(item => {
                    if (item?.isRequiredInInheritedConnection === false && item?.parameterType?.includes(RestServiceConstants?.OAUTH2)) {
                        let obj = {
                            configConnectionParameterTypeId: item?.id,
                            parameterName: item?.parameterType,
                            parameterValue: "",
                            isValid: true
                        };
                        newParameters.push(obj);
                    }
                });               
                return newParameters;
            }
        } catch (error) {
        }
    }

    const onPopUpClose = () => {
        setOpenPopup(!openPopup);
        renderComponents(AddConnectionComponents?.CONNECT_VIEW);
        windowRef?.current?.close();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (openPopup) {
                getAccessToken();
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [openPopup])

    const checkIfWindowOpen = () => {
        if (windowRef?.current && !windowRef?.current?.closed) {
            console.log('Another window is open.');
        } else {
            console.log('No other window is open.');
            onPopUpClose();
        }
    };

    const testConnection = async (existingData) => {
        if(!selectedConnection?.templateConnectionId){
            await addNativeConnection();
            return;
        }
        let param = [];

        param["state"] = generateRandomString(50);

        let connectionData = existingData?.parameters ? existingData : connection;

        connectionData?.parameters?.forEach(parameter => {
            switch (parameter?.parameterName) {
                case RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE:
                    param["grantType"] = parameter?.parameterValue;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID:
                    param["clientId"] = parameter?.parameterValue;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL:
                    param["redirectUri"] = parameter?.parameterValue;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL:
                    param["authUrl"] = parameter?.parameterValue;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.SCOPE:
                    param["scope"] = parameter?.parameterValue;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.CODE_VERIFIER:
                    param["pkce"] = parameter?.parameterValue;
                    break;
                case RestServiceConstants.OAUTH2_INPUTS.CODE_CHALLENGE_METHOD:
                    param["codeChallengeMethod"] = parameter?.parameterValue;
                    break;
                default:
                    break;
            }
        });

        let data = {
            client_id: param?.clientId || null,
            redirect_uri: param?.redirectUri || null,
            auth_url: param?.authUrl || null,
            scope: param?.scope || null,
            state: param?.state || null
        };
     
        if (param?.grantType === "authorization_code_with_pkce") {
            const pkceData = await generateCode(param?.pkce);

            data["responseType"] = "code" || null;
            data["code_challenge"] = param?.codeChallengeMethod != "S256" ? pkceData?.verifier : pkceData?.base64EncodedHash || null;
            data["code_challenge_method"] = param?.codeChallengeMethod || null;            
        }

        const queryString = Object?.keys(data)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join("&");

        const popupWindow = window.open(`/begin-oauth?${queryString}`, "_blank", "width=800,height=600");

        windowRef.current = popupWindow;

        let newConnection = {
            parameters: connectionData?.parameters,
            name: selectedConnection?.description,
            sysConnectionTypeId: connectionData?.sysConnectionTypeId || connectionData?.baseConnectionTypeId,
            isSaasAuthSource: false,
        }
        setConnection(newConnection);
        setStateConnectionParameter(data.state);
        renderComponents(AddConnectionComponents?.CONNECTING_POPUP);
        setOpenPopup(!openPopup);
    }

    const showMenuList = (event, connection) => {
        if (event) {
            event.stopPropagation();
        }
        setSelectedConnection(connection);
        let uId = getConnectionUId(connection?.templateConnectionId);
        setConnectionUId(uId);
        setPlugInAnchorEl(event?.currentTarget);
    };

    const closeMenuList = () => {
        setPlugInAnchorEl(null);
    };

    const onMenuClick = (action) => {
        if (action === "re-Authorize") {
            reAuthorize();
            closeMenuList();
            return;
        }
        setshowConfirmationModel(true);
        setStateConnectionParameter(action);
        closeMenuList();
    };

    const reAuthorize = async () => {
        let response = await getConnectionByUId(connectionUId);

        let newConnection = {
            parameters: response?.parameters,
            name: selectedConnection?.description,
            sysConnectionTypeId: 2,
            isSaasAuthSource: false,
        }
        testConnection(newConnection);
    }

    const onConfirmationClose = (action) => {
        switch (action) {
            case "disconnect":
                disconnectConnection();
                break;
            case "delete":
                deleteConnection();
                break;
            default:
                break;
        }
        setshowConfirmationModel(false);
    };

    return (
        <>
            {renderComponent?.showAddConnection === true && (
                <AddConnection connectionTypes={connectionTypes}
                    usedCloudApplicationsId={usedCloudApplicationsId}
                    handleListView={handleListView}
                    onSelectConnection={onSelectConnection}
                    showMenuList={showMenuList}
                    plugInAnchorEl={plugInAnchorEl}
                    closeMenuList={closeMenuList}
                    onMenuClick={onMenuClick} />
            )}
            {renderComponent?.showConnectView === true && (
                <ConnectPageView testConnection={testConnection}
                    selectedConnection={selectedConnection} />
            )}
            {renderComponent?.showConnectingPopup === true && (
                <ConnectingPageView selectedConnection={selectedConnection}
                    onPopUpClose={onPopUpClose} />
            )}
            {renderComponent?.showSuccessfullConnectedPage === true && (
                <SuccessfullConnectedView selectedConnection={selectedConnection}
                    disconnectConnection={disconnectConnection} />
            )}
            <ConfirmationModel selectedConnection={selectedConnection}
                open={showConfirmationModel}
                stateConnectionParameter={stateConnectionParameter}
                onConfirmationClose={onConfirmationClose} />
        </>
    );
}
export default AddConnectionContoller;