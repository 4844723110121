import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import PluginParameter from "../../../components/asheetPlugin/plugin-parameters/plugin-parameters";
import { useEffect, useMemo, useRef, useState } from "react";
// import moment from "moment";
import serverFunctions from "../../../components/asheetPlugin/utils2/serverFunctions";
import { getUserSheet, userSheetLink } from "../../../services/plugin-sheet.service";
import { toast } from "react-toastify";
import { testData } from "../../../services/test-data.service";
import * as collectionService from '../../../services/data-collection.service';
import * as connectionService from '../../../services/data-source.service';
import moment from "moment-timezone";
import { getClientDateTime } from "../../../shared/code-challanges/code-challanges";
import { FilterParameterNames, ParameterTypeValue, ReservedKeywords } from "../../../constants/app-enum";
import { updateParameterValues } from "../../data-collections/utils/template-collection-parameter";
import { getASTenantId, getRefreshToken, getToken } from "../../../shared/local-storage-handler/local-storage-handler";
import { CircularProgress } from "@mui/material";
import _ from "lodash";

const PluginParameterController = () => {

    const { collectionUId: collectionUId } = useParams();
    const [state, setState] = useState();
    const [data, setData] = useState();
    const [selectedCollection, setSelectedCollection] = useState();
    const [selectedConnection, setSelectedConnection] = useState();
    const [isLoader, setIsLoader] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [testDataCollectionResult, setTestDataCollectionResult] = useState();
    const [activeSheetDetails, setActiveSheetDetails] = useState();
    const regexDateTImeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
    const [activeSheetName, setActiveSheetName] = useState("");
    const [isSheetLinked, setIsSheetLinked] = useState(null);
    const activeSheet = useRef({});

    const parameterTypes = Object.values(ParameterTypeValue());
    const parameterNames = Object.values(ReservedKeywords());

    const [filterConfig, setFilterConfig] = useState({
        numberOfFilterGroupAllowed: 0,
        numberOfFilterPerGroupAllowed: 0,
        numberOfSortAllowed: 0,
        operatorAllowedInFilterGroup: [],
        operatorAllowedInFilter: [],
        filterTypeArray: [],
        filterGroupExceeded: "",
        filterPerGroupExceeded: "",
        SortExceeded: ""
    });

    const stateRef = useRef();

    useEffect(async () => {
        setSelectedCollection(location.state)
        getUserSheetData();
        getCollectionDetails();
    }, [])

    const handleBack = () => {
        navigate("/plugin", {
            state: selectedCollection
        })
    }

    const updateAPI = async (userSheetModel) => {
        try {
            const response = await userSheetLink(userSheetModel);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else {
                setIsSheetLinked(true);
            }
        } catch (error) {

        } finally {
            setIsLoader(false);
        }
    }
    useEffect(() => {

        const updateSheetData = async () => {

            try {
                // let parameterObject = await extractParameterObject();

                let model = {
                    lastUserRenderedBy: state.createdBy,
                    collectionUId: collectionUId,
                    documentLink: activeSheetDetails?.sheetInfo?.activeSheetUrl,
                    sheetId: testDataCollectionResult?.errorMessage ? null : activeSheetDetails?.sheetInfo?.activeSheetId,
                    sheetName: testDataCollectionResult?.errorMessage ? null : activeSheetDetails?.sheetInfo?.name,
                    isLinked: testDataCollectionResult?.errorMessage == null ? true : false,
                    isRemoved: false,
                    parameterValues: state.collectionParameters ?? null,
                    dataRenderRange: testDataCollectionResult?.errorMessage ? null : activeSheetDetails?.sheetInfo?.dataRange,
                    noOfRawRows: testDataCollectionResult?.recordRows,
                    noOfFinalRows: testDataCollectionResult?.errorMessage ? null : activeSheetDetails?.sheetInfo?.noOfRows,
                    bytes: testDataCollectionResult?.dataSize,
                    lastRenderWasSuccessful: testDataCollectionResult?.errorMessage == null ? true : false,
                    lastErrorMessage: testDataCollectionResult?.errorMessage,
                };

                if (!activeSheetDetails?.success && testDataCollectionResult?.errorMessage == null) {
                    model.lastErrorMessage = activeSheetDetails?.message;
                    model.lastErrorTrace = activeSheetDetails?.stack;
                    model.lastRenderWasSuccessful = false;
                    model.isLinked = false;
                }

                await updateAPI(model);
                // await setSheetMetadata()
                getUserSheetData();
            } catch (error) {
                console.error('Error updating sheet data:', error);
            }
        };

        if (testDataCollectionResult?.errorMessage || (testDataCollectionResult && activeSheetDetails)) {
            updateSheetData();
        }
    }, [testDataCollectionResult, activeSheetDetails]);

    const executeData = async (state) => {
        setIsLoader(true)
        try {
            let testDataCollectionModal = {
                connectionUId: state.connectionUId,
                sysCollectionTypeId: state.sysCollectionTypeId,
                sourceName: state.sourceName,
                restRequestMethod: state.restRequestMethod,
                restRequestIsAsync: true,
                restSendAsCD: state.restSendAsCD,
                collectionParameters: state.collectionParameters,
                collectionColumns: state?.collectionColumns,
                uId: state.uId,
                returnFlatternData: !state.returnRawData,
                sourceNamePart4: state.sourceNamePart4,
                templateCollectionId: state.templateCollectionId,
                collectionFilters: getFilterModal('filter'),
                collectionSorts: getFilterModal('sort'),
                collectionLimit: getFilterModal('limit'),
                restDataTransformationScript: state?.restDataTransformationScript,
                returnRawData:  state.returnRawData,
                restBody: selectedCollection?.restBody ?? null
            }
            var testDataResult = await testData(testDataCollectionModal, setData, setDataToSheet, null, true, setState, setIsLoader, true, null, null, null, null,null,null,true);
            let response = testDataResult?.hasError ? testDataResult : testDataResult?.data;
            setTestDataCollectionResult(response);
            if (response?.errorMessage === null) {
                if (typeof activeSheet.current === 'object' && activeSheet.current !== null) {
                    activeSheet.current.lastRenderSuccessfulDateTime = moment();
                }
            }
        } catch (error) {

        }
        // setIsLoader(false)
    };

    let isRunning = false;

    const fetchData = async () => {

        if (isRunning) return;

        // Set the flag to true indicating an execution is in progress
        isRunning = true;
        const sheetChanges = await serverFunctions?.getSheetChange();
        try {
            if (sheetChanges?.isRefresh == "true") {
                // setFlag(false)
                setTimeout(async () => {
                    const state = await getCollectionDetails()
                    stateRef.current = state
                    await executeData(stateRef.current)
                    
                }, 1000);
            }
            if (sheetChanges?.sheetChange !== "null") {
                const sheetChange = JSON.parse(sheetChanges?.sheetChange);
                // stateRef.current.name = sheetChange.name
                await checkChanges(sheetChange)
            }
        } catch {

        } finally {
            isRunning = false;
        }

    }

    useEffect(() => {
        const intervalId = setInterval(async () => {

            await fetchData();

        }, 1000); // Poll every 1 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const checkChanges = async (sheetChange) => {
        try {
            if (activeSheet.current?.sheetId === sheetChange?.id) {
                setActiveSheetName(sheetChange?.name);
            }
            const response = await getUserSheet(sheetChange?.collectionUId.toString())
            if (response?.data !== "") {
                const userSheetModel = response?.data;
                if (sheetChange?.changeType === "Rename") {
                    userSheetModel["sheetName"] = sheetChange?.name;
                    await updateAPI(userSheetModel);
                }
                else if (sheetChange?.changeType === "Remove") {

                    userSheetModel["isLinked"] = false;
                    // userSheetModel["sheetId"] = null;
                    await updateAPI(userSheetModel);
                    // userSheetData();
                    if (activeSheet.current?.collectionUId === sheetChange?.collectionUId) {
                        setIsSheetLinked(false);
                        setActiveSheetName("");
                    }
                }
            }

        } catch (error) {
            toast.error(error);
        }
    }

    const getUserSheetData = async () => {
        try {
            const response = await getUserSheet(collectionUId);
            const sheetIds = await serverFunctions.getSheetsIds();
            if (response?.hasError) {
                setIsSheetLinked(false)
            }
            else {
                if (response?.data?.isLinked) {
                    let sheetExists = sheetIds?.some(item => item?.sheetId == response?.data?.sheetId);
                    if (!sheetExists) {
                        const userSheetModel = {
                            ...response.data,
                            isLinked: false,
                            sheetId: null
                        };
                        await updateAPI(userSheetModel);
                    }
                    else {
                        let collectionParameters = JSON.parse(response?.data?.parameterValues);
                        let searchParameters = [];
                        collectionParameters?.map((parameter) => {
                            if (parameterTypes.includes(parameter.parameterTypeCD) && parameter.parameterTypeCD !== 'Fixed Hidden' && parameter.parameterTypeCD !== "") {

                                searchParameters.push(parameter);
                            }
                        })
                        setState((prevState) => ({
                            ...prevState,
                            searchParameters: searchParameters
                        }));
                        stateRef.current = {
                            ...state,
                            searchParameters: searchParameters
                        }


                        setActiveSheetName(response?.data?.sheetName);
                        activeSheet.current = response?.data;
                    }
                    setIsSheetLinked(sheetExists);
                }
                else {
                    setIsSheetLinked(false);
                }

            }

        } catch (error) {
            toast.error(error);
        }
    }


    const getCollectionDetails = async () => {
        const data = await collectionService.getCollectionDetails(collectionUId);
        const state = data?.data
        let searchParameters = [];
        let filterSortParameters = [];
        if (state?.collectionParameters?.length)
            state.collectionParameters = _.orderBy(state.collectionParameters, ['displayOrder', 'id'], ['asc', 'asc'])

        let updatedParams = updateParameterValues(state.collectionParameters);
debugger
        updatedParams.forEach((parameter) => {

            if (parameterTypes.includes(parameter.parameterTypeCD) && parameter.parameterTypeCD !== 'Fixed Hidden' && parameter.parameterTypeCD !== '') {
                searchParameters.push(parameter)
                state.searchParameters = searchParameters
            }
            else if (parameterNames.includes(parameter.parameterName)) {
                filterSortParameters.push(parameter)
                state.filterSortParameters = filterSortParameters
            }
        })

        setState(state)

        stateRef.current = (state)
        return state
    }

    useEffect(() => {
        setSelectedCollection(location.state)
        // getCollectionDetails()

    }, [])

    const createSheetAndAddData = async (header, data, sheetName, sheetId, collectionUId) => {
        if (data === null || !data.length) {
            toast.error("No Data");
        }
        console.log(data)

        let response = await serverFunctions.addSheet(header, data, sheetName, sheetId, collectionUId);

        setIsLoader(false);

        setActiveSheetDetails(response);
        setActiveSheetName(response?.sheetInfo?.name);

    };

    const getFilterModal = (filterLable, data) => {
        const state = stateRef.current;
        if (state?.collectionParameters.length > 0) {
            var filterParameter = state?.collectionParameters.filter(i => i.parameterName == "Filter");
            var sortParameter = state?.collectionParameters.filter(i => i.parameterName == "Sort");
            var limitParameter = state?.collectionParameters.filter(i => i.parameterName == "Limit");
            if (filterParameter && filterParameter?.length > 0 && filterLable === "filter") {
                var filter = JSON.parse(filterParameter[0].defaultValue);
                return getFilterData(filter)
            }
            if (sortParameter && sortParameter?.length > 0 && filterLable === "sort") {
                var sort = JSON.parse(sortParameter[0].defaultValue);
                return sort
            }
            if (limitParameter && limitParameter?.length > 0 && filterLable === "limit") {
                var limit = JSON.parse(limitParameter[0].defaultValue);
                return limit
            }
        }
    }

    const getFilterData = (filterData) => {
        const filterGroups = filterData?.map(group => {
            const filters = group?.filters.map(item => ({
                field: item.field,
                value: item.value,
                operator: item.operator,
                condition: item.condition,
                displayName: item.displayName,
                highValue: item.highValue,
                values: item.values,
                dateLabel: item.dateLabel,
                dataTypeName: item.dataTypeName,
                valueLabel: item.valueLabel,
                dateLabelValue: item.dateLabelValue,
                conditionLabel: item.conditionLabel,
            }));
            return { filters: filters, operator: group?.operator };
        });

        if (!filterGroups || filterGroups.length === 0) {
            return [];
        }
        return [{ filterGroups: filterGroups }];
    };

    const setDataToSheet = async (data) => {
        const state = stateRef.current
        const header = state?.collectionColumns?.sort((a, b) => a.displayOrder - b.displayOrder);

        const rowData = Array.isArray(data) ? data : [...data]
        rowData?.map((item) => {
            for (const [key, value] of Object.entries(item)) {

                if (regexDateTImeFormat.test(`${value}`)) {
                    item[key] = convertToDateFormat(`${value}`)
                }
            }
        })
        const sheetName = activeSheetName ? activeSheetName : `AS_${state?.name}`

        await createSheetAndAddData(header, rowData, sheetName, activeSheet.current?.sheetId, state?.uId);
    }

    // const extractParameterObject = async () => {
    //     const collectionParametersObject = [];
    //     const addParameterObjects = (items, name, valueKey) => {
    //         items.forEach(item => {
    //             collectionParametersObject.push({
    //                 "ParameterName": name,
    //                 "ParameterValue": item[valueKey]
    //             });
    //         });
    //     };

    //     const filter = getFilterModal('filter');
    //     const sort = getFilterModal('sort');
    //     const limit = getFilterModal('limit');
    //     let searchParameter = [];
    //     state?.collectionParameters.forEach((item) => {
    //         if (item.displayName === "Filter" && filter.length > 0) {
    //             addParameterObjects(filter[0].filterGroups[0].filters, "Filter", "conditionLabel");
    //         } else if (item.displayName === "Sort" && sort.length > 0) {
    //             addParameterObjects(sort, "Sort", "value");
    //         } else if (item.displayName === "Limit" && limit) {
    //             collectionParametersObject.push({
    //                 "ParameterName": "Limit",
    //                 "ParameterValue": limit.limit
    //             });
    //         } else {

    //             searchParameter.push({
    //                 "ParameterName": item.parameterName,
    //                 "ParameterValue": item.parameterValue
    //             });

    //         }
    //     });
    //     if (searchParameter.length)
    //         collectionParametersObject.push({ "searchParameter": searchParameter })

    //     return collectionParametersObject;
    // };

    const convertToDateFormat = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }



    const handleRunOrRefresh = async (state) => {
        const updatedState = stateRef.current;

        if (isSheetLinked) stateRef.current = updatedState;

        executeData(updatedState);
    };

    useEffect(() => {
        if (selectedCollection?.sysConnectionTypeId) {
            getCollectionFilterConfig(selectedCollection?.sysConnectionTypeId);
        }
    }, [selectedCollection])

    const getCollectionFilterConfig = (connectionTypeId) => {
        collectionService.getCollectionFilterConfig(connectionTypeId)
            .then((response) => {
                if (response?.data) {

                    setFilterConfig({
                        numberOfFilterGroupAllowed: response.data?.numberOfFilterGroupAllowed,
                        numberOfFilterPerGroupAllowed: response.data?.numberOfFilterPerGroupAllowed,
                        numberOfSortAllowed: response.data?.numberOfSortAllowed,
                        operatorAllowedInFilterGroup: response.data?.operatorAllowedInFilterGroup,
                        operatorAllowedInFilter: response.data?.operatorAllowedInFilter,
                        filterTypeArray: response.data?.filterTypeArray,
                        filterGroupExceeded: response.data?.filterGroupExceeded,
                        filterPerGroupExceeded: response.data?.filterPerGroupExceeded,
                        SortExceeded: response.data?.sortExceeded
                    });
                }
            })
    }

    const openDataVisualizer = async () => {
        // setFlag(true)
        const params = {
            collectionUId: collectionUId,
            token: getToken(),
            refreshToken: getRefreshToken(),
            asTenantUid: getASTenantId()
        }
        var url = `collection/data-visualizer?collectionUId=${params?.collectionUId}&AccessToken=${params?.token}&RefreshToken=${params?.refreshToken}&ASTenantUId=${params?.asTenantUid}&closeWindowOnPopupClose=true`;

        await serverFunctions.openDialog(url).then().catch(alert);
    };

    useEffect(() => {
        stateRef.current = state

    }, [state])

    const openAddNewConnectionDialog = async () => {
        const params = {
            connectionUId: selectedCollection?.connection?.uId,
            token: getToken(),
            refreshToken: getRefreshToken(),
            asTenantUid: getASTenantId()
        }
        const url = `data-collections/add-new-data-collection?connectionUId=${params?.connectionUId}&AccessToken=${params?.token}&RefreshToken=${params?.refreshToken}&ASTenantUId=${params?.asTenantUid}&closeWindowOnPopupClose=true`

        await serverFunctions.openDialog(url).then().catch(alert);
    }


    // const [flag, setFlag] = useState(false);

    // useEffect(() => {
    //     let intervalId = null;

    //     if (flag) {
    //         intervalId = setInterval(() => {
    //             console.log("Running continuously...");
    //             fetchData()
    //             // setFlag(false)
                
    //         }, 1000); // Executes every 1 second
    //     } else if (intervalId) {
    //         clearInterval(intervalId);
    //     }

    //     // Cleanup function to clear interval when component unmounts or flag changes
    //     return () => {
    //         if (intervalId) {
    //             clearInterval(intervalId);
    //         }
    //     };
    // }, [flag]);

    return (
        <>
            {isSheetLinked === null ?
                <CircularProgress
                    className={`circularLoader`}
                    thickness={4}
                    size={50}
                // color={styles.primaryColor}
                /> :
                <PluginParameter
                    // formattedTime={formattedTime}
                    handleBack={handleBack}
                    selectedCollection={selectedCollection}
                    state={state}
                    setState={setState}
                    isOpen={isLoader}
                    isLinked={isSheetLinked}
                    activeSheetName={activeSheetName}
                    activeSheet={activeSheet}
                    handleRunOrRefresh={handleRunOrRefresh}
                    filterConfig={filterConfig}
                    openDataVisualizer={openDataVisualizer}
                    openAddNewConnectionDialog={openAddNewConnectionDialog}
                />}
        </>
    )
}

export default PluginParameterController;