import React, { Fragment, useMemo, useRef } from "react";
import "./VariableList.scss";
import "./../../user-permission/user-permission.scss"

import { Link } from "react-router-dom";
import { Autocomplete, Checkbox, Paper, Stack, TableHead, Tooltip, Typography, tooltipClasses } from "@mui/material";
import styles from "../../../styles/_exports.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { ASTextField } from "../../../shared/as-text-fields";
import { selectedMenuItemBackgroundColor } from "../../data-collections/collection-parameters/collection-parameters";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";

import _, { uniqBy } from "lodash";
import SearchOrFilterVariablesHeaderController from "../../../controllers/connections/variables/search-or-filter-variables-controller";
import SystemDefinedVariableList from "./system-defined-variable-list";
import TableMainHeader from "../../../shared/misc/table-main-header";
import { AppEnum } from "../../../constants/app-enum";
import ASAutoComplete from "../../../shared/as-inputs/as-auto-complete";
import { ANY } from "../../../controllers/connections/variables/variables-controller";



const ASheetReportNamesToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#16365F',
    boxShadow: theme.shadows[1],
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: 600,
    maxWidth: 400,
    padding: "15px 12px"
  },
}));


const userDefinedVariablesTableHeader = [
  { headerTitle: "Variable Name" },
  { headerTitle: "Data Type" },
  { headerTitle: "Default Value" },
  { headerTitle: "" },
  { headerTitle: "Number of Collection" },
  { headerTitle: "Connection for Testing the List"},
  { headerTitle: "" },
];



export default function VariableList(props) {

  const { state, userDefinedState, templateCompany, connectionList, setUserDefinedState, onFocusSearchBoxHandler,
    onBlurSearchBoxHandler, onChangeSearchUserVariableHandler, searchInputRef, isShrunk } = props;


  const users = useMemo(() => userDefinedState.filteredUsers || [], [userDefinedState.filteredUsers])
  const tableContainerRef = useRef(null);


  return (
    <Fragment>
      <div className="users-container">

        <TableMainHeader
          totalRows={users?.length}
          tableTitle="User Defined Variables"
          hasAddButton={true}
          onClick={props.handleAddNewVariable}
        />

        <Stack>
          <SearchOrFilterVariablesHeaderController
            userDefinedState={userDefinedState}
            setUserDefinedState={setUserDefinedState}
            connectionList={connectionList}
            templateCompany={templateCompany}
          />
        </Stack>

        <Paper className="user-list-paper">
          <TableContainer className="tableHeight" ref={tableContainerRef}>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>

                <TableRow sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                  {
                    userDefinedVariablesTableHeader.map((dataHead, index) => {

                      return (
                        <TableCell
                          key={`user-defined-variable-header ${index}`}
                          align='left'
                          sx={{ color: styles.secondaryColor, fontWeight: styles.semiBold }}
                          scope="row"
                        >
                          {dataHead.headerTitle}
                        </TableCell>
                      )
                    })
                  }
                </TableRow>

              </TableHead>

              {
                !users.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={userDefinedVariablesTableHeader?.length} align="center">
                        No records found.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }


              <TableBody>
                {
                  users?.map((user, index) => {
                    return (
                      <TableRow hover key={`userDefinedState${index}`}>
                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.name}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.dataTypeName}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor, padding: "0px 16px", }}
                        >
                          {user?.dataTypeName == AppEnum.DataTypeName.DynamicList ? (
                            <div className="show-list-div">
                              {
                                (user?.complexTypeValue === null)
                                  ? null
                                  : (user?.complexTypeValue.DataCollectionUId === "") ?
                                    <>{user?.complexTypeValue.DefaultValue}</>
                                    : user?.showList && user?.listValues?.length > 0 ?

                                      <div className="variable-default-value-dropdown">
                                        <Stack spacing={2}>

                                          <ASAutoComplete
                                            options={uniqBy(user?.listValues?.map((option) => ({
                                              key: option?.value,
                                              label: option?.key
                                            })), 'key')
                                            }


                                            selectedOptionValue={user?.complexTypeValue?.DefaultValue}
                                            item={user}
                                            onChange={props.selectDefaultValue}
                                            onBlur={props.handleBlankDefaultValue}
                                            value={user?.listValues.find((item) => item?.value == user?.complexTypeValue?.DefaultValue)?.key}
                                            label={"Dynamic List Values"}
                                            tableContainerRef={tableContainerRef}
                                          />

                                        </Stack>
                                      </div>
                                      :
                                      <Typography className="show-list"
                                        onClick={() => props?.onShowListClick(user)}>
                                        Show List
                                      </Typography>
                              }
                            </div>
                          ) : null}

                          {user?.dataTypeName == AppEnum.DataTypeName.List ?
                            <div className="list-dropdown">
                              <ASTextField
                                size="small"
                                className="dropdown-list"
                                type={"text"}
                                fullWidth
                                value={user?.defaultUserDefinedListValue?.Label}
                                label="List Values"
                                variant="outlined"
                                select={true}
                                SelectProps={{
                                  MenuProps: {
                                    className: "menu-role-div",
                                    sx: selectedMenuItemBackgroundColor,
                                  },
                                }}
                              >
                                {
                                  user?.complexTypeValue?.map((list, index) => {
                                    return (
                                      <MenuItem value={list?.Label} key={`dynamic ${index}`}
                                        onClick={() => {
                                          props?.selectDefaultValue(list, user, index, "list")
                                        }}>

                                        {list.Label}
                                      </MenuItem>
                                    );
                                  })
                                }
                              </ASTextField>
                            </div>
                            : ""}
                          {user?.dataTypeName !== AppEnum.DataTypeName.DynamicList && user?.dataTypeName !== AppEnum.DataTypeName.List ?
                            (user?.dataTypeName == "Boolean" ?
                              <div className="boolean-box">
                                <div className="true=checkbox-div">
                                  <Checkbox
                                    onBlur={(event) => props.handleOutsideClick(user)}
                                    checked={JSON.parse(user?.primitiveTypeValue)}
                                    onChange={(event) => props.primitiveTypeValueChangeHandler(user, event.target.checked)}
                                    sx={muiCheckboxStyle}
                                  />
                                  {`${user?.primitiveTypeValue}`}
                                </div>
                              </div>
                              : <div className="list-dropdown">
                                <ASTextField
                                  size="small"
                                  key={user?.name}
                                  className="dropdown-list"
                                  type={user?.dataTypeName == AppEnum.DataBaseDataType.Date ? "date" : user?.dataTypeName == AppEnum.DataBaseDataType.Number ? "number" : "text"}
                                  fullWidth
                                  variant="outlined"
                                  value={user?.primitiveTypeValue}
                                  onBlur={(event) => props.handleOutsideClick(user)}
                                  onChange={(event) => props.primitiveTypeValueChangeHandler(user, event.target.value)}
                                />
                              </div>)
                            : ""}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ color: styles.mutedColor }}
                        >
                          <Checkbox
                            onBlur={() => {
                              if (user?.dataTypeName !== AppEnum.DataTypeName.DynamicList
                                && user?.dataTypeName !== AppEnum.DataTypeName.List) {
                                props.handleOutsideClick(user);
                              }
                            }}
                            checked={
                              (user?.dataTypeName !== AppEnum.DataTypeName.DynamicList && user?.dataTypeName !== AppEnum.DataTypeName.List && (user?.primitiveTypeValue === "" || user?.primitiveTypeValue === null)) ||
                              (user?.dataTypeName == AppEnum.DataTypeName.DynamicList && (user?.complexTypeValue?.DefaultValue == null || user?.complexTypeValue?.DefaultValue == "")) ||
                              (user?.dataTypeName == AppEnum.DataTypeName.List && (user?.defaultUserDefinedListValue?.Label === ANY))
                            }
                            onChange={() => (user?.dataTypeName == AppEnum.DataTypeName.DynamicList || user?.dataTypeName == AppEnum.DataTypeName.List) ?
                              props?.selectDefaultValue("", user, index, ANY) : props.primitiveTypeValueChangeHandler(user, "")}
                            sx={muiCheckboxStyle}
                          />
                          No Value
                        </TableCell>

                        <TableCell
                          style={{ position: 'relative' }}
                          align="left"
                          sx={{ color: styles.mutedColor }}
                          onMouseEnter={() => props.handleMouseEnter(index)}
                          onMouseLeave={props.handleMouseLeave}
                        >
                          {
                            templateCompany ? "-" :
                              <ASheetReportNamesToolTip
                                title={props.getUserDefineVariableReportsName(index).map((name, i) => (
                                  <div key={i}>• {name}</div>
                                ))}
                                placement="right-start"
                                open={props.isPopupVisible === index && user?.totalReport > 0}
                              >
                                {(user?.totalReport ? user?.totalReport === 1 ? `${user?.totalReport} Collection` : `${user?.totalReport} Collections` : "No Collection")}
                              </ASheetReportNamesToolTip>
                          }
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.templateConnectionId !== null && templateCompany ? (
                            connectionList?.find(list => list.id === user?.templateConnectionId)?.name || ''
                          ) : (connectionList?.filter(list => list.templateConnectionId === user?.templateConnectionId).length > 1) && user?.sysDataTypeId === AppEnum.DataTypeId.DynamicList ? (
                            user?.templateConnectionId !== null && (
                              <div className="list-dropdown">
                                <ASTextField
                                  size="small"
                                  className="dropdown-list"
                                  type="text"
                                  fullWidth
                                  value={
                                    user?.connectionId ||
                                    connectionList.find(i => i.templateConnectionId === user?.templateConnectionId)?.id || ''
                                  }
                                  label="Connection"
                                  variant="outlined"
                                  select
                                  SelectProps={{
                                    MenuProps: {
                                      className: "menu-role-div",
                                      sx: selectedMenuItemBackgroundColor,
                                    },
                                  }}
                                  onChange={event => props?.onConnectionChange(event, user)}
                                >
                                  {connectionList
                                    .filter(i => i.templateConnectionId != null && i.templateConnectionId === user?.templateConnectionId)
                                    .map((list, index) => (
                                      <MenuItem
                                        value={list?.id}
                                        key={`connection-${list.id || index}`}
                                      >
                                        {list?.name || "Unnamed Connection"}
                                      </MenuItem>
                                    ))}
                                </ASTextField>
                              </div>
                            )
                          ) : user?.templateConnectionId !== null && (
                            connectionList?.find(list => list.templateConnectionId === user?.templateConnectionId)?.name || ''
                          )}

                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          <MoreVertIcon
                            id={`${String(index)}+user-vertIcon`}
                            aria-controls={
                              userDefinedState.userDefine
                                ? `${String(index)}+menu`
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              userDefinedState.userDefine ? "true" : undefined
                            }
                            onClick={(event) =>
                              props.openUserMenuOption(event, true, index)
                            }
                            className="edit-user-icon"
                          >
                            Dashboard
                          </MoreVertIcon>

                          <Menu
                            id={`${String(index)}+menu`}
                            aria-labelledby={index}
                            anchorEl={
                              userDefinedState.selectedRowIndex === index ? userDefinedState.anchorEl : null
                            }
                            open={
                              userDefinedState.selectedRowIndex === index ? userDefinedState.userDefine : false
                            }
                            onClose={(event) => props.closeUserMenuOption(event)}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            className="edit-user-menu"
                          >
                            <MenuItem
                              disabled={!templateCompany && user?.templateConnectionId !== null && user?.isTemplateVariable}
                              onClick={(event) =>
                                props.openConfirmationModal(event, index)
                              }
                            >
                              Delete Variable
                            </MenuItem>
                            <MenuItem

                              disabled={!templateCompany && user?.templateConnectionId !== null
                                && user?.isTemplateVariable && (user?.dataTypeName == AppEnum.DataTypeName.List || user?.dataTypeName == AppEnum.DataTypeName.DynamicList)}
                              onClick={(event) =>
                                props.editVariable(event, user)
                              }
                            >
                              Edit Variable
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <MuiDialog
          isShowPopUp={props.confirmationModalOpen}
          secondaryButtonAction={props.closeConfirmationModal}
          primaryButtonAction={props.confirmDelete}
          closeDialog={props.closeConfirmationModal}
          secondaryButtonTitle="No"
          primaryButtonTitle="Yes"
          titleDescription="Are you sure you want to delete this variable?"
        />

        <SystemDefinedVariableList
          state={state}
          isShrunk={isShrunk}
          onFocusSearchBoxHandler={onFocusSearchBoxHandler}
          onBlurSearchBoxHandler={onBlurSearchBoxHandler}
          onChangeSearchUserVariableHandler={onChangeSearchUserVariableHandler}
          searchInputRef={searchInputRef}
        />
      </div>
    </Fragment>
  );
}
