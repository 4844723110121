// src/ConnectedSources.js
import _ from "lodash";
import './plugin.scss';
import { Button, CardContent, CircularProgress, Typography } from '@mui/material';
import PluginCollectionsController from "../../../controllers/asheetPlugin/plugin-collections/plugin-collections";
import DefaultView from "../../common/defaultView/DefaultView";
import { useASContext } from "../../../controllers/context-api/as-context";
import { addBuyPlan } from "../../../services/checkout-service";
import { refreshTokenHandler } from "../../../core/axios.interceptor";
import { getASTenantId, getItem, removeItem, setToken } from "../../../shared/local-storage-handler/local-storage-handler";
import { setSessionStorageItem } from "../../../shared/session-storage-handler/session-storage-handler";


export const DEMO_COMPANY_UID = "E4UCNG3H4Q"
const PluginHome = (props) => {

  const { selectedCollection, setSelectedConnection, dataCollections, activeTab, collectionRenderList, onChangeTabs, connectionList, handleBack, deleteDataCollection } = props;
  const asContext = useASContext(); 
  const selectedTenant =getASTenantId()

  const onBuyFreePlan = () => {
    const parsedUrl = new URL(window.location.href);

    let data = {
      licenseTypeUId: "AS.FREE.1",
      licenseUnit: 1,
      boughtSupport: false,
      supportUnit: 0,
      redirectUrl: parsedUrl.origin + "/plugin"
    }
    addBuyPlan(data)
      .then(async (response) => {
        await refreshTokenHandler();
        console.log(response)
        window.open(response?.data?.entityUId, "_self")
      }).catch = (error) => {
      }
  }

  const openWebVersion = () => {
    const parsedUrl = new URL(window.location.href)
    window.open(parsedUrl.origin, '_blank')
  }

  const defaultCollectionMenu = () => {
    return (
      <div>
        <div className="tabs">
          <div
            className={`tab ${activeTab === 'connection' ? 'active' : ''}`}
            onClick={() => onChangeTabs('connection')}
          >
            By Connection
          </div>
          <div
            className={`tab ${activeTab === 'groupName' ? 'active' : ''}`}
            onClick={() => onChangeTabs('groupName')}
          >
            By Groups Name
          </div>
          {selectedTenant  != DEMO_COMPANY_UID  && 
          <div className='connect-new-btn' onClick={() => onChangeTabs('connect-new')}>
            Connect New
          </div>}
        </div>

        <div className="content">

          {activeTab === 'connection' && collectionRenderList(connectionList, 'connection')}
          {activeTab === 'groupName' && collectionRenderList(connectionList, 'groupName')}

        </div>
      </div>
    )
  }

  const selectTenant = async () => {
    let tenant = {
      ASTenantId: "E4UCNG3H4Q",
      Name: "Demo Company",
      OwnerSub: "4375c22e-4f4c-4566-83ff-3cafd450e665",
      UserRoleId: "4_ROLE_RU"
    }
    asContext?.tenants?.setSelectedTenant(tenant);
    
    setToken("ASTenantId", tenant?.ASTenantId); //setting TenantId in localStorage
    setSessionStorageItem("ASTenantId", tenant?.ASTenantId)
    removeItem("DataCollectionListFilter");
    window.location.reload();

  }

  return (
    <div className="pluginContainer">


      {!(asContext.subscriptionValidation.isSubscriptionExist) ? (
        <DefaultMessage
          title="You have no subscription added"
          firstPart="Click on "
          buttonLink={"/checkout"}
          buttonText="checkout"
          secondButttonFunc={onBuyFreePlan}
          secondButtonText={"Version"}
          secondPart=" to subscribe or use free "
          firstButtonFunc={openWebVersion}
        />) :
        !selectedCollection ?

          dataCollections === undefined ? "" :
            dataCollections?.length ? defaultCollectionMenu() :

              <DefaultMessage
                title="You have not created any collections yet."
                firstPart="Click on"
                firstButtonFunc={openWebVersion}
                buttonText=" Add "
                secondButttonFunc={selectTenant}
                secondButtonText={"Demo Company"}
                secondPart="to add your first collection or try "
              />
          :

          <PluginCollectionsController
            handleBack={handleBack}
            dataCollections={dataCollections}
            selectedCollection={selectedCollection}
            activeTab={activeTab}
            prevAccessedCollections={props.prevAccessedCollections}
            deleteDataCollection={deleteDataCollection}
          />
      }


    </div>
  );
};

export default PluginHome;

const DefaultMessage = ({ title, firstPart, secondPart, buttonText, secondButtonText, secondButttonFunc, firstButtonFunc }) => {
  return (
    <div>
      <div sx={{ width: '50%' }}>
        <CardContent className="error-div">
          <Typography variant="h4" sx={{ fontSize: 18, textAlign: "center" }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 18, textAlign: "center" }}>
            {firstPart}
            <span className="custom-link" onClick={firstButtonFunc} sx={{ fontSize: 16, textAlign: "center", padding: 0}} style={{ textDecoration: 'none', color: "#1616D2" }}>
              {buttonText}
            </span>
            {secondPart}
            <span onClick={secondButttonFunc} sx={{ fontSize: 16, textAlign: "center", padding: 0 }} className="custom-link" style={{ textDecoration: 'none', color: "#1616D2" }}>
              {secondButtonText}
            </span>
          </Typography>
        </CardContent>
      </div>
    </div>
  );
};
