import { Grid, styled, Toolbar, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import styles from "./../../styles/_exports.module.scss";
import "./header.scss";
import UserNameMenuContainer from "../../controllers/header-username-menu/username-menu-container";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TenantsMenuContainer from "../../controllers/tenants-menu/tenants-menu-container";
import { useASContext } from "../../controllers/context-api/as-context";
import { ASButton } from "../as-button/as-button";

// export const DRAWER_WIDTH = "21%";
// export const DRAWER_WIDTH_EXP = "20.9%";
export const DRAWER_WIDTH = "282px";
export const DRAWER_WIDTH_EXP = "280px";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${theme.spacing(9)} - 1px)`,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
export function Header(props) {

    const asContext = useASContext();

    const { userName, openDrawer, routeToLoginPage, tenants, tenantName,
        getUserName, openTenantMenu, tenantAnchorEl, closeTenantMenu, isMenuOpen, isIndividualUser, setSavedOrganization, refresh_token,toShowDropAndPopUp,
        iFrameOption, openNewWindow } = props;
    

    return (
        <AppBar
            open={openDrawer}
            position="fixed"
            sx={{
                width: `calc(100% - ${openDrawer ? DRAWER_WIDTH : 0})`,
                ml: `${DRAWER_WIDTH}`,
                backgroundColor: styles.bodyBackground,
                boxShadow: "none",
                color: "black",
                paddingTop: "10px",
            }}
        >
            {
                asContext?.spinner?.isDataLoading &&
                <div className="as-spinner"></div>
            }

            <Toolbar sx={{ display: "block" }}>

                <Grid container spacing={2}>

                    <Grid item xs={8}>

                        {
                            tenants?.length > 0 &&
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontSize: "12px",
                                        display: "block",
                                    }}
                                    noWrap
                                    component="div"
                                    className="organisation"
                                >
                                    Organization
                                </Typography>

                                <div className="organisation-name-div">
                                    <Typography variant="subtitle2" noWrap component="div" className="organisation-name"
                                        onClick={openTenantMenu}
                                    >

                                        <span>{tenantName}</span>

                                        {    toShowDropAndPopUp
                                             &&
                                            <ArrowDropDownIcon />
                                        }
                                    </Typography>

                                    {
                                        toShowDropAndPopUp && 
                                        < TenantsMenuContainer
                                            tenants={tenants}
                                            getUserName={getUserName}
                                            tenantAnchorEl={tenantAnchorEl}
                                            closeTenantMenu={closeTenantMenu}
                                            isMenuOpen={isMenuOpen}
                                            isIndividualUser={isIndividualUser}
                                            setSavedOrganization={setSavedOrganization}
                                            refresh_token={refresh_token}
                                        />
                                    }
                                </div>
                            </div>
                        }

                    </Grid>

                    <Grid item xs={4} sx={{ textAlign: "end" }} className='avatar-grid'>

                        {iFrameOption && <Typography className="iFrame-option" onClick={openNewWindow} >Open in new window</Typography>}
                        <UserNameMenuContainer
                            userName={userName ? userName : 'Login'}
                            isUserLoggedIn={userName ? true : false}
                            styles={styles}
                        />

                        <Typography
                            sx={{ position: "relative", bottom: "16px", left: "10px" }}
                            variant="subtitle2"
                            noWrap
                            component="span"
                            className={userName ? "header-username" : "header-username-hover"}
                            onClick={() => {
                                if (!userName)
                                    routeToLoginPage()
                            }}
                        >
                            {userName ? userName : 'Login'}
                        </Typography>

                    </Grid>

                </Grid>
            </Toolbar>
        </AppBar>
    );
}
