// src/ConnectedSources.js
import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from "lodash";
import { useLocation, useNavigate } from 'react-router-dom';
import * as collectionService from '../../../services/data-collection.service';
import { truncateString } from '../../../shared/code-challanges/code-challanges';
import PluginHome from '../../../components/asheetPlugin/plugin/plugin';
import { useASContext } from '../../context-api/as-context';
import { getUserSheetByDocumentLink } from "./../../../services/plugin-sheet.service";
import serverFunctions from "../../../components/asheetPlugin/utils2/serverFunctions";
import { toast } from 'react-toastify';

const PluginController = () => {
    const asContext = useASContext();
    const navigate = useNavigate();
    const [connectionList, setConnectionList] = useState();
    const [dataCollections, setDatacollections] = useState();
    const location = useLocation()
    const [selectedCollection, setSelectedConnection] = useState()
    const [prevAccessedCollections, setPrevAccessedCollections] = useState();
    const activeTab = asContext?.plugin?.pluginActiveTab

    useEffect(() => {
        const fetchAndRoute = async () => {
            if (location?.state) {
                await getUserDataCollection();
                route(location?.state);
            }
        };
        fetchAndRoute();
    }, [location]);

    const route = (event) => {
        asContext?.plugin?.setConnectionSelected(event);
        setSelectedConnection(event)
    }

    const getUserDataCollection = async () => {
        const data = await collectionService.getDataCollection()
        if (!data?.hasError) {
            // (x => x.GroupName !== null || x.IsActive !== false || x.IsOnlyForDynamicList !== true || x.Connection.IsActive !== false)
            const activeCollections = data?.data.filter((x) => {
                return (x?.isActive == true && x?.connection?.isActive == true)
            });
            setDatacollections(activeCollections)
        }
    }


    useEffect(() => {
        if (dataCollections?.length)
            onChangeTabs(activeTab)
    }, [dataCollections, activeTab])

    const onChangeTabs = (name) => {
        let connectionGroupsList;
        let connectionList;

        if (name === "groupName") {
            connectionList = _.uniqBy(dataCollections, function (collection) {
                return collection?.groupName;
            });
        }
        if (name === "connection") {
            connectionList = _.uniqBy(dataCollections, function (collection) {
                return collection?.connection?.name;
            });
        };
        if (name === "connect-new") {
            navigate("/plugin/add-connection");
            return;
        }
        // setActiveTab(name);
        asContext?.plugin?.setPluginActiveTab(name);
        setConnectionList(connectionList);
    }

    const handleBack = () => {
        asContext?.plugin?.setConnectionSelected(null);
        setSelectedConnection(null);
        onChangeTabs(activeTab);
    }

    const getChildGroupNames = useMemo(
        () => (groupName) => {
            if (!groupName || !dataCollections) {
                return [];
            }
            return [...new Set(dataCollections
                .filter(collection => collection.groupName === groupName && collection.childGroupName?.trim() !== '')
                .map(collection => collection.childGroupName))];
        },
        [dataCollections]
    );

    const collectionRenderList = (list, key) => {

        return (
            <div id={key} className="active">
                {list?.map((item) => (
                    <div className='' >
                        <div onClick={() => route(item)} className="source" key={item.id}>
                            <img
                                src={`../../assets/${item.connection.connectionImageIcon}`}
                                alt={item.connection.name}
                            />
                            {key == "connection" ? <div className="plugin-group-name">{truncateString(item.connection.name, 25)}</div> :
                                (
                                    <div>
                                        {item[key]}
                                        <span>{truncateString(item.connection.name, 15)}</span>
                                        {getChildGroupNames(item[key]).map((subGroupName, index) => (
                                            <div
                                                className="indented subgroup"
                                                key={index}>
                                                {subGroupName}
                                                <span>
                                                    {truncateString(item.connection.name, 31 - subGroupName.length)}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            <img
                                className="info-icon"
                                src={`/assets/infoIcon.svg`}
                            />
                        </div>
                    </div>
                ))}
            </div>)
    }

    const getAccessedCollections = useCallback(async () => {
        try {

            const sheetIds = await serverFunctions.getSheetsIds();

            if (sheetIds?.length > 0 && sheetIds[0].documentUrl) {
                const response = await getUserSheetByDocumentLink(sheetIds[0].documentUrl);
                setPrevAccessedCollections(response?.data); // Update state with fetched data
            } else {
                console.error("No valid sheetIds found or documentUrl is missing");
            }
        } catch (error) {
            console.error("Error fetching collections:", error);
        }
    }, [prevAccessedCollections]);

    useEffect(() => {
        getUserDataCollection()
        getAccessedCollections();
    }, []);

    const deleteDataCollection = async(collectionUId) => {
        const response = await collectionService.deleteDataCollection(collectionUId)
        if (!response?.hasError) {
            getUserDataCollection()
            getAccessedCollections();
          toast.success("Collection deleted successfully.");
        }
    
      }

      useEffect(() => {
        const receiveMessage = (event) => {
            // debugger
          if (event.data ==='closeDialog') {
            return;
          }
          const receivedData = event.data;
        //   if (receivedData && receivedData.code) {
        //     setCode(receivedData.code);
        //   }
        };
    
        window.addEventListener('message', receiveMessage);
        return () => {
          window.removeEventListener('message', receiveMessage);
        };
      }, []);


    return (
        <PluginHome
            selectedCollection={selectedCollection}
            setSelectedConnection={setSelectedConnection}
            dataCollections={dataCollections}
            activeTab={activeTab}
            collectionRenderList={collectionRenderList}
            onChangeTabs={onChangeTabs}
            connectionList={connectionList}
            handleBack={handleBack}
            prevAccessedCollections={prevAccessedCollections}
            deleteDataCollection={deleteDataCollection}
        />
    );
};

export default PluginController;


