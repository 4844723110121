import PropTypes from 'prop-types';

export const AsheetPlugInConstant = {
    menuItems : [
        { label: 'Disconnect', action: 'disconnect' },
        { label: 'Re-Authorize', action: 're-Authorize' },
        { label: 'Delete', action: 'delete' },
    ],   
};

export class AddConnectionComponents {
    static ADD_CONNECTION = "showAddConnection";
    static CONNECT_VIEW = "showConnectView";
    static CONNECTING_POPUP = "showConnectingPopup";
    static SUCCESSFULL_CONNECTED = "showSuccessfullConnectedPage";
}

export class ComponentRendering {
    showAddConnection = false;  
    showConnectView = false;
    showConnectingPopup = false;
    showSuccessfullConnectedPage = false;
}

export const userSheetLinkModel = {
    lastUserRenderedBy: PropTypes.string.isRequired,
    collectionUId: PropTypes.string.isRequired,
    documentLink: PropTypes.string,
    sheetId: PropTypes.string,
    isLinked: PropTypes.bool.isRequired,
    isRemoved: PropTypes.bool.isRequired,
    parameterValues: PropTypes.string.isRequired,
    dataRenderRange: PropTypes.string,
    noOfRawRows: PropTypes.number.isRequired,
    noOfFinalRows: PropTypes.number.isRequired,
    bytes: PropTypes.number.isRequired,
    lastRenderWasSuccessful: PropTypes.bool.isRequired,
    lastRenderSuccessfulDateTime: PropTypes.string.isRequired,
    lastErrorMessage: PropTypes.string.isRequired,
    lastErrorTrace: PropTypes.string.isRequired,
};