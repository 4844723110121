import { Menu, MenuItem } from "@mui/material";
import { menuIconSvg, successIconSvg } from "../../../constants/common-svgs";
import { ASButton } from "../../../shared/as-button/as-button";
import { menuItems } from "../../common/plugin-menu/plugin-menu";
import { truncateString } from "../../../shared/code-challanges/code-challanges";
import './plugin-collections.scss';
import { useMemo } from "react";
import { DEMO_COMPANY_UID } from "../plugin/plugin";
import { getASTenantId } from "../../../shared/local-storage-handler/local-storage-handler";
import { ASConnection } from "../../../shared/menu-icons/as-connection";
import { ASDataCollections } from "../../../shared/menu-icons/as-data-collections";



const PluginConnections = (props) => {


  const { dataCollections, prevAccessedCollections, anchorEl, handleClick, handleClose, navigateToCollectionParameter, formatDate, handleBack,
    selectedCollection, activeTab ,openAddNewConnectionDialog, handleMenuOptionClick} = props;


  const selectedCollectionConnectionName = useMemo(() => truncateString(selectedCollection?.connection?.name, 18), [selectedCollection?.connection?.name]);
  const selectedCollectionGroupeName = useMemo(() => truncateString(selectedCollection.groupName, 15), [selectedCollection.groupName]);
  const selectedTenant = getASTenantId()
  return (
    <div>
      <div className="flex border-bottom">
        <div>
          <img
            src={`/assets/back-button.svg`}
            alt="Back"
            style={{ marginLeft: "5px", marginTop: "5px", cursor: "pointer" }}
            onClick={handleBack}
          />
        </div>
        <div className="source-header">
          <img
            src={`../../assets/${selectedCollection.connection.connectionImageIcon}`}
            alt="Xero"
          />
          {activeTab === 'connection' ? (
            <div className="plugin-group-name">
              {selectedCollectionConnectionName}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="plugin-group-name">
                {selectedCollectionGroupeName}
              </div>
              <span className="f-9">
                {selectedCollectionConnectionName}
              </span>
            </div>
          )}
          <img className="plugin-collections-info-icon" src={`/assets/infoIcon.svg`} />
        </div>
      </div>

      <div className="content">
        <div id="groupName" className="active">
          {dataCollections?.map((item) => {
            const accessedCollection = prevAccessedCollections?.find(prevItem => prevItem.collectionUId === item.uId);
            let icon =  <ASDataCollections size={'2em'} className={'data-coll-icon'} /> ;
            let message = null;

            if (accessedCollection) {
              if (accessedCollection?.isLinked) {
                icon = successIconSvg;
                message = `Last ran at ${formatDate(accessedCollection?.lastRenderSuccessfulDateTime)} successfully`;
              } 
              // else {
              //   icon =  <ASConnection size={'2em'} className={'data-coll-icon'} />
              //   message = `Last run was failed at ${formatDate(accessedCollection?.lastRenderSuccessfulDateTime)}`;
              // }
            }

            return (
              <div onClick={() => navigateToCollectionParameter(item)} className="connction-list" key={item.uId}>
                {icon}
                <div className={`plugin-collections-content  ${!message ? 'not-linked-collection-margin' : ''}`}>
                  {truncateString(item?.name, 30)}
                  <div className="f-9">
                    {message ? message : null}
                  </div>
                  <div className={`plugin-menu-icon ${!message ? 'plugin-menu-margin' : ''}`} onClick={(event) => handleClick(event,item)}>
                    {menuIconSvg}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {selectedTenant != DEMO_COMPANY_UID &&
        <div className="add-new-connection-btn">
          <ASButton onClick={()=>openAddNewConnectionDialog()} primary={true}>Add New</ASButton>
        </div>}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="plugin-menu-container"
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={()=>handleMenuOptionClick(item)}
            className="plugin-menu-container-data"
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PluginConnections;