import { useEffect, useState } from 'react'
import { getDataSourceByUID } from "../../../../services/data-source.service";
import SelectConnection from '../../../../components/data-collections/select-connection/select-connection';
import { CollectionParametersState } from '../new-data-collection-state';
import { useASContext } from '../../../context-api/as-context';
import { AppEnum } from '../../../../constants/app-enum';
import { useParams } from 'react-router-dom';


const SelectConnectionController = (props) => {

    const { state, setState, getCollectionTypeList, prevStepRef, setParametersState} = props;

    const asContext = useASContext();


    useEffect(() => {
        if (state.connectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
        }
    }, [state.connectionUId])

    // it sets the connection UId state
    const onSelectConnectionHandler = (connection) => {
        let connectionUId = connection?.uId;
        setState((prevState) => { return { ...prevState, templateCollectionId: null, connectionUId: connectionUId } });

        if (connection?.sysConnectionTypeId === AppEnum.MergeCollection.MergeSysConnectionTypeId) {
            asContext?.globalSpinner?.setIsGlobalSpinner(false);
            setState((prevState) => { return { ...prevState, hasMergeCollections: true, } });
        }
        else {
            if (!asContext?.globalSpinner?.isGlobalSpinner) {
                asContext?.globalSpinner?.setIsGlobalSpinner(true);
            }
            let sysConnectionTypeId = state?.connectionList.find(i => i.uId === connectionUId)?.sysConnectionTypeId;
            getCollectionTypeList(sysConnectionTypeId);
            setState((prevState) => { return { ...prevState, hasMergeCollections: false, } });
        }

        setState((prevState) => { return { ...prevState, sourceName: "", sourceNamePart4: "", sysCollectionSubTypeId: '', returnRawData: false, restRequestMethod: null, uiLabel: '', collectionParameters: [] } });
        resetParameterState();
    }

    const getCurrentConnectionDetails = async (connectionUId) => {
        const response = await getDataSourceByUID(connectionUId);
        if (response?.data) {
            props.setSelectedConnection(response.data);
            return response?.data
            // console.log(res?.data)
        }
    }


    const resetParameterState = () => {
        let parametersState = new CollectionParametersState();

        setParametersState(() => { return { ...parametersState, } });
    }
    
    useEffect(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const connectionUId = urlParams.get('connectionUId');
        const connectionData = await getCurrentConnectionDetails(connectionUId)
        onSelectConnectionHandler(connectionData)
    }, [])

    return (
        <SelectConnection
            state={state}
            prevStepRef={prevStepRef}
            onSelectConnectionHandler={onSelectConnectionHandler}
        />
    );
}

export default SelectConnectionController;
