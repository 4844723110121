import React, { Fragment, useCallback, useMemo } from "react";
import PluginConnections from "../../../components/asheetPlugin/plugin-collections/plugin-collections";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import { AppEnum } from "./../../../constants/app-enum";

import { getClientDateTime } from "../../../shared/code-challanges/code-challanges";
import moment from "moment-timezone";
import { getASTenantId, getRefreshToken, getToken } from "../../../shared/local-storage-handler/local-storage-handler";
import serverFunctions from "../../../components/asheetPlugin/utils2/serverFunctions";
import * as collectionService from '../../../services/data-collection.service';
import { toast } from "react-toastify";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";

const PluginCollectionsController = ({ dataCollections, handleBack, selectedCollection, activeTab, prevAccessedCollections, deleteDataCollection }) => {


  const [filteredDataCollections, setFilteredDataCollection] = useState();
  // const [prevAccessedCollections, setPrevAccessedCollections] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeCollection, setActiveCollection] = useState(null);
  const [confirmDeleteDialog, setConfirmDeleteModal] = useState(false);

  const navigate = useNavigate();

  // Function to get accessed collections
  // const getAccessedCollections = useCallback(async () => {
  //   try {
  //     const sheetIds = await serverFunctions.getSheetsIds();

  //     if (sheetIds?.length > 0 && sheetIds[0].documentUrl) {
  //       const response = await getUserSheetByDocumentLink(sheetIds[0].documentUrl);
  //       setPrevAccessedCollections(response?.data); // Update state with fetched data
  //     } else {
  //       console.error("No valid sheetIds found or documentUrl is missing");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching collections:", error);
  //   }
  // }, [prevAccessedCollections]);

  // useEffect(() => {
  //   getAccessedCollections();
  // }, []); // `useEffect` depends on the memoized function


  // Filtering the collections based on the menu
  const filterDataCollectionByMenu = useMemo(() => {
    if (!selectedCollection) return [];

    const { groupName, connection } = selectedCollection;

    switch (activeTab) {
      case AppEnum.CollectionFilterId.groupName:
        return _.filter(dataCollections, (collection) => collection?.groupName === groupName);

      case AppEnum.CollectionFilterId.connection:
        return _.filter(dataCollections, (collection) => collection?.connection?.id === connection?.id);

      default:
        return dataCollections;
    }
  }, [activeTab, selectedCollection, dataCollections]);

  // Use effect to set filtered data when the selectedCollection changes
  useEffect(() => {
    setFilteredDataCollection(filterDataCollectionByMenu);
  }, [filterDataCollectionByMenu]);

  const handleClick = (event, data) => {
    event.stopPropagation();
    setActiveCollection(data)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  const navigateToCollectionParameter = (collection) => {
    navigate(`/plugin/data-collections/${collection.uId}`, {
      state: collection,
    });
  };

  const handleMenuOptionClick = (data) => {
    if (data.action === "edit") {
      openDataVisualizer()
    }
    if (data.action === "delete") {
      setConfirmDeleteModal(true)
    }
    handleClose()
  }

  const closeDialog = () => {
    setConfirmDeleteModal(false)
    setActiveCollection(null)

  }



  const formatDate = (lastRenderTime) => {

    if (!lastRenderTime) return moment().format('h:mm A');

    const localTime = getClientDateTime(lastRenderTime)

    const momentTime = moment(localTime);
    return momentTime.isSame(moment(), 'day')
      ? momentTime.format('h:mm A')
      : momentTime.format('MMM D, h:mm A');
  };

  const openAddNewConnectionDialog = async () => {
    const params = {
      connectionUId: selectedCollection?.connection?.uId,
      token: getToken(),
      refreshToken: getRefreshToken(),
      asTenantUid: getASTenantId()
    }
    const url = `data-collections/add-new-data-collection?connectionUId=${params?.connectionUId}
                                &AccessToken=${params?.token}&RefreshToken=${params?.refreshToken}
                                &ASTenantUId=${params?.asTenantUid}
                                &closeWindowOnPopupClose=true`

    await serverFunctions.openDialog(url).then().catch(alert);
  }

  const openDataVisualizer = async () => {
    const params = {
      collectionUId: activeCollection?.uId,
      token: getToken(),
      refreshToken: getRefreshToken(),
      asTenantUid: getASTenantId()
    }
    var url = `collection/data-visualizer?collectionUId=${params?.collectionUId}
                                &AccessToken=${params?.token}&RefreshToken=${params?.refreshToken}
                                &ASTenantUId=${params?.asTenantUid}
                                &closeWindowOnPopupClose=true`;

    await serverFunctions.openDialog(url).then().catch(alert);
  };

  const deleteCollection = () => {
    deleteDataCollection(activeCollection?.uId)
    closeDialog()
  }

  return (
    <Fragment>


      <PluginConnections
        dataCollections={filteredDataCollections}
        prevAccessedCollections={prevAccessedCollections}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        navigateToCollectionParameter={navigateToCollectionParameter}
        formatDate={formatDate}
        handleBack={handleBack}
        selectedCollection={selectedCollection}
        activeTab={activeTab}
        openAddNewConnectionDialog={openAddNewConnectionDialog}
        handleMenuOptionClick={handleMenuOptionClick}
      />

      <MuiDialog
        isShowPopUp={confirmDeleteDialog}
        secondaryButtonAction={closeDialog}
        primaryButtonAction={deleteCollection}
        closeDialog={closeDialog}
        secondaryButtonTitle="No"
        primaryButtonTitle="Yes"
        className="plugin-dialog"
        titleDescription="Are you sure you want to delete this collection?"
      />
    </Fragment>
  );

};

export default PluginCollectionsController;
