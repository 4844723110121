import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import "../mui-dialog/mui-dialog.scss"
import Slide from "@mui/material/Slide";

export default function MuiDialog(props) {
  const {
    isShowPopUp,
    primaryButtonAction,
    secondaryButtonAction,
    closeDialog,
    secondaryButtonTitle,
    primaryButtonTitle,
    titleDescription,
    className
  } = props;

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
        <div>
          {
            <Dialog
              open={isShowPopUp}
              // TransitionComponent={Transition}
              keepMounted
              className= {className ? className :"mui-dialog-popup"}
              onClose={secondaryButtonAction}
            >
              <DialogTitle className="popup-title">
                {titleDescription}
              </DialogTitle>

              <DialogActions className="popup-buttons-div">
                {secondaryButtonTitle && 
                <Button className="first-button" onClick={secondaryButtonAction}>
                  {secondaryButtonTitle}
                </Button>}
                <Button className="second-button" onClick={primaryButtonAction}>
                  {primaryButtonTitle}
                </Button>
              </DialogActions>
            </Dialog>
          }
        </div>
  );
}
