import { useEffect, useMemo, useState } from 'react'
import BasicInformation from "../../../../components/data-collections/basic-information/basic-information";
import * as collectionService from "../../../../services/data-collection.service";
import { getDataSourceByUID } from "../../../../services/data-source.service";
import * as GroupService from '../../../../services/groups-service';
import BasicInformationPopup from '../../../../components/data-collections/basic-information/basic-information-popup';
import { DynamicList } from '../../../connections/variables/variables-Popup';
import {getTemplateCompany} from '../../../../services/variable-service'
import { AppEnum } from '../../../../constants/app-enum';
import { onlyLettersAndSpaces } from '../../../../shared/code-challanges/code-challanges';
const BasicInformationController = (props) => {

    const { state, setState, getCollectionTypeList, prevStepRef, initialCollectionName, saveCollection, setIsDialogOpen, isDialogOpen, isBasicInfoSubmitted, isUpdatingCollection, isCloudLoading, isDynamicListValues, setIsDynamicListValues,
        isVariableUsedInCollection, isRest, selectedConnection, showSaveCollection, isTestedAfterSaveAppears, setShowTestCollectionPopup, isTestedData
    } = props;
    const [groups, setGroups] = useState([]);
    const [subGroups, setSubGroups] = useState([]);
    const [isTemplateCompany, setIsTemplateCompany] = useState(false);
    const [dataTypes, setDataTypes] = useState([]);
    const [valueColumnDataType, setValueColumnDataType] = useState({dataTypeId: "", dataType: "", format: ""});



    useEffect(() => {
        getGroups();

        const getAllDataTypes = async () => {
            const response = await collectionService.getFieldTypeList();
            setDataTypes(response?.data);
        }
        getAllDataTypes();
    }, [])

    useEffect(() => {
        if (isDynamicListValues?.ValueColumn?.length) {
            const selectedColumns = props?.state?.columnSchemaList?.filter(variable => variable.isSelected);
            const matchingColumn = selectedColumns.find(variable => variable.displayName === isDynamicListValues?.ValueColumn);

            if (matchingColumn?.dataType) {
                setValueColumnDataType((prevState) => {
                    return {
                        ...prevState,
                        ["dataTypeId"]: matchingColumn.dataType
                    }
                });
            }
        }
    }, [isDynamicListValues?.ValueColumn]);

    const isValueColumnDataTypeBoolean = useMemo(() => {
        return valueColumnDataType?.dataTypeId === AppEnum.DataTypeId.Boolean;
      }, [valueColumnDataType?.dataTypeId]);

    //Fetching all the groups and subgroups
    const getGroups = () => {
        GroupService.getAllGroups().then((response) => {
            if (response?.data) {
                setGroups(response?.data);
            }
        })
    }

    useEffect(() => {
        if (state.connectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
        }
    }, [state.connectionUId])


    useEffect(() => {

        if (state.name && state.name != initialCollectionName) {
            const getCollectionByName = setTimeout(() => {
                collectionService.getCollectionByName(state.name)
                    .then((response) => {
                        if (response.data) {
                            setState((prevState) => { return { ...prevState, basicInfoError: "Collection with this name already exists" } });
                        }
                        else if(!onlyLettersAndSpaces(state.name)){
                            setState((prevState) => { return { ...prevState, basicInfoError: "Special characters are not allowed in this field" } });
                        }
                        else {
                            setState((prevState) => { return { ...prevState, basicInfoError: null } });
                        }
                    });
            }, 500);

            return () => clearTimeout(getCollectionByName);
        }

        setState((prevState) => { return { ...prevState, basicInfoError: null } });

    }, [state.name])

    useEffect(() => {
        async function fetchTemplateCompany() {
            try {
                const response = await getTemplateCompany();
                if (response?.data == true && props?.isRest) {
                    setIsTemplateCompany(true);
                }
            } catch (error) {
                console.error("Error fetching template company details:", error);
            }
        }
        fetchTemplateCompany();
    }, []);

    // it sets the state of data collection name
    const onChangeDataCollectionNameHandler = (event) => {
        const value = event.target.value;
        setState((prevState) => { return { ...prevState, name: value, basicInfoError: null} });

        if(value.length > 0 && !onlyLettersAndSpaces(value)){
            setState((prevState) => { return { ...prevState, basicInfoError: "Special characters are not allowed in this field" } });
        }
    }

    // it sets the state of source name part 2
    const onChangeSourceNamePart2NameHandler = (event) => {
        setState((prevState) => { return { ...prevState, sourceNamePart2: event.target.value } });
    }

    // it sets the state of group name
    const onChangeGroupNameHandler = (event) => {
        setState((prevState) => { return { ...prevState, groupName: event?.target?.value } });
    }

    // it sets the state of sub group name
    const onChangeSubGroupNameHandler = (event) => {
        setState((prevState) => { return { ...prevState, childGroupName: event?.target?.value } });
    }

    // it sets the connection UId state
    const onSelectConnectionHandler = (connectionUId) => {

        // let connection = JSON.parse(event.target.value);
        setState((prevState) => { return { ...prevState, connectionUId: connectionUId } });
        let sysConnectionTypeId = state?.connectionList.filter(i => i.uId === connectionUId)[0]?.sysConnectionTypeId;
        let baseConnectionTypeId = state?.connectionList.filter(i => i.uId === connectionUId)[0]?.baseConnectionTypeId;
        getCollectionTypeList(baseConnectionTypeId ? baseConnectionTypeId : sysConnectionTypeId);
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                props.setSelectedConnection(res.data);
            }
        });
    }

    // it sets the category UId state
    // const onSelectCategoryHandler = (categoryUId) => {
    //     setState((prevState) => { return { ...prevState, categoryUId: categoryUId } });
    // }

    // it allows the collection to be treated as connection
    const onCheckCollectionAsConnection = (event) => {
        if(! event?.target?.checked){
            setIsDynamicListValues(new DynamicList());
        }
        setState((prevState) => { return { ...prevState, isCollectionAsDataSource: event?.target?.checked } });
    }

    const onSelectGroupNameHandler = (selectedGroup) => {

        if (selectedGroup) {
            setState((prevState) => { return { ...prevState, groupName: selectedGroup?.groupName } });
            setState((prevState) => { return { ...prevState, childGroupName: "" } });
            const group = groups?.find(obj => obj.groupName === selectedGroup?.groupName);
            setSubGroups(group?.subGroups);
        }
        else
            setState((prevState) => { return { ...prevState, groupName: "" } });
    }

    const onSelectSubGroupNameHandler = (selectedSubGroup) => {

        if (selectedSubGroup) {
            setState((prevState) => { return { ...prevState, childGroupName: selectedSubGroup?.subGroupName } });
        }
        else
            setState((prevState) => { return { ...prevState, childGroupName: "" } });
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
        setState((prevState) => { return { ...prevState, name: "", groupName:"", childGroupName:"" } });
    }

    const handleOnChnageFilterInput = (event,input) => {
        setIsDynamicListValues((prevState) => {
        return {
        ...prevState, [input] : event?.target?.value
        }
        });
    }

    const onChangeColumnEndpointApiHandler = (event) => {
        setState((prevState) => { return { ...prevState, columnApiEndpoint: event?.target?.value } });
    }

    const onToggleFilterSupported = (event) => {
        setState((prevState) => { return {
            ...prevState,
            isFilterSupported: event?.target?.checked,
            areAllFieldsFilterable: !event?.target?.checked ? false : prevState?.areAllFieldsFilterable,
        }});
    };

    const onToggleSortSupported = (event) => {
        setState((prevState) => ({
            ...prevState,
            isSortSupported: event?.target?.checked,
            areAllFieldsSortable: !event?.target.checked ? false : prevState?.areAllFieldsSortable,
        }));
    };
    
    const onTogglePagingSupported = (event) => {
        setState((prevState) => ({
            ...prevState,
            isPagingSupported: event?.target?.checked,
        }));
    };
    

    const onToggleAllFieldsFilterable = (event) => {
        setState((prevState) => {return {
            ...prevState,
            areAllFieldsFilterable: event?.target?.checked,
        }});
    };

    const onToggleAllFieldsSortable = (event) => {
        setState((prevState) => {return {
            ...prevState,
            areAllFieldsSortable: event?.target?.checked,
        }});
    };

    const validateDataType = (value, dataType) => {
        dataType = dataType?.toLowerCase();
        
        const integerTypeInfo = { dataTypeName: "integer", format: AppEnum.DataTypeFormats.Integer };
        const doubleTypeInfo = { dataTypeName: "decimal number", format: AppEnum.DataTypeFormats.Double };

        // Define a mapping for data type names and formats
        const typeInfo = {
            [AppEnum.DataBaseDataType.Number.toLowerCase()]: integerTypeInfo,
            [AppEnum.DataBaseDataType.Int.toLowerCase()]: integerTypeInfo,
            [AppEnum.DataBaseDataType.Long.toLowerCase()]: integerTypeInfo,
            [AppEnum.OtherDataBaseType.SmallInt]: integerTypeInfo,
            [AppEnum.OtherDataBaseType.Percentage]: integerTypeInfo,
            [AppEnum.DataBaseDataType.Currency.toLowerCase()]: { dataTypeName: "currency", format: AppEnum.DataTypeFormats.Decimal },
            [AppEnum.OtherDataBaseType.Decimal]: { dataTypeName: "decimal number", format: AppEnum.DataTypeFormats.Decimal },
            [AppEnum.DataBaseDataType.String.toLowerCase()]: { dataTypeName: "text" },
            [AppEnum.OtherDataBaseType.Boolean]: { dataTypeName: "true/false" },
            [AppEnum.DataBaseDataType.Date.toLowerCase()]: { dataTypeName: "date", format: AppEnum.DataTypeFormats.Date },
            [AppEnum.OtherDataBaseType.Time]: { dataTypeName: "time", format: AppEnum.DataTypeFormats.Time },
            [AppEnum.DataBaseDataType.DateTime.toLowerCase()]: { dataTypeName: "datetime", format: AppEnum.DataTypeFormats.DateTime },
            [AppEnum.OtherDataBaseType.DateTimeOffset]: { dataTypeName: "datetimeoffset", format: AppEnum.DataTypeFormats.DateTimeOffset },
            [AppEnum.OtherDataBaseType.Guid]: { dataTypeName: "guid", format: AppEnum.DataTypeFormats.Guid },
            [AppEnum.OtherDataBaseType.Double]: doubleTypeInfo,
            [AppEnum.OtherDataBaseType.Single]: doubleTypeInfo,
            [AppEnum.OtherDataBaseType.TimeSpan]: { dataTypeName: "time span", format: AppEnum.DataTypeFormats.TimeSpan }
        };
    
        // Check if value is an empty string
        if (!value) {
            const info = typeInfo[dataType] || { dataTypeName: '', format: '' };
            return { isValid: true, ...info };
        }
    
        // Handle the provided data types
        switch (dataType) {
            case AppEnum.DataBaseDataType.Number.toLowerCase():
            case AppEnum.DataBaseDataType.Int.toLowerCase():
            case AppEnum.DataBaseDataType.Long.toLowerCase():
            case AppEnum.OtherDataBaseType.SmallInt:
            case AppEnum.OtherDataBaseType.Percentage.toLowerCase():
                const isInteger = !isNaN(Number(value)) && Number.isInteger(Number(value));
                return { isValid: isInteger, dataTypeName: "integer", format: "Integer" };
    
            case AppEnum.DataBaseDataType.Currency.toLowerCase(): {
                const isDecimal = /^[+-]?\d+(\.\d+)?$/.test(value);
                return { isValid: isDecimal, ...typeInfo[dataType] };
            }
    
            case AppEnum.OtherDataBaseType.Decimal:
                const isDecimal = /^(\d*\.\d+|\d+\.?)$/.test(value);
                return { isValid: isDecimal, ...typeInfo[dataType] };
    
            case AppEnum.DataBaseDataType.String.toLowerCase():
                const isString = typeof value === 'string';
                return { isValid: isString, ...typeInfo[dataType] };
    
            case AppEnum.OtherDataBaseType.Boolean:
                const isBoolean = ['true', 'false'].includes(value.toLowerCase());
                return { isValid: isBoolean, ...typeInfo[dataType] };
    
            case AppEnum.DataBaseDataType.Date.toLowerCase(): {
                const isValid = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value); // YYYY-MM-DD
                return { isValid, ...typeInfo[dataType] };
            }
    
            case AppEnum.OtherDataBaseType.Time: {
                const isValid = /^([01]\d|2[0-3]):[0-5]\d(:[0-5]\d(?:\.\d{1,9})?)?$/.test(value); // HH:MM, HH:MM:SS, HH:MM:SS.sss
                return { isValid, ...typeInfo[dataType] };
            }
    
            case AppEnum.DataBaseDataType.DateTime.toLowerCase(): {
                const isValid = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])[T\s]([01]\d|2[0-3]):[0-5]\d(:[0-5]\d(?:\.\d{1,9})?)?$/.test(value) ||
                    /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value);
                // YYYY-MM-DD[ T]HH:MM, YYYY-MM-DD[ T]HH:MM:SS, YYYY-MM-DD[ T]HH:MM:SS.sss (either 'T' or a space)
                return { isValid, ...typeInfo[dataType] };
            }
    
            case AppEnum.OtherDataBaseType.DateTimeOffset: {
                const isValid = /^\d{4}-\d{2}-\d{2}[T\s]([01]\d|2[0-3]):[0-5]\d(:[0-5]\d(?:\.\d{1,9})?)?(?:Z|([+-](?:0[0-9]|1[0-4]):[0-5]\d))?$/.test(value); // YYYY-MM-DDTHH:MM±HH:MM
                return { isValid, ...typeInfo[dataType] };
            }
    
            case AppEnum.OtherDataBaseType.Guid:
                const isValid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value);
                return { isValid, ...typeInfo[dataType] };
    
            case AppEnum.OtherDataBaseType.Double:
            case AppEnum.OtherDataBaseType.Single:
                const isValidFloat = /^-?\d+(\.\d+)?$/.test(value.replace(/,/g, ''));
                return { isValid: isValidFloat, ...typeInfo[dataType] };
    
            case AppEnum.OtherDataBaseType.TimeSpan: {
                const isTimeSpan = /^([0-9]{1}|(?:0[0-9]|1[0-9]|2[0-3])):([0-5]?[0-9])(?::([0-5]?[0-9])(?:\.(\d{1,9}))?)?$/.test(value); //hh:mm:ss.ffffff
                return { isValid: isTimeSpan, ...typeInfo[dataType] };
            }
    
            default:
                return { isValid: true, dataTypeName: '', format: '' };
        }
    };
    
    const handleDefaultValueChange = async (event, input, id) => {
        const dataTypeId = id ?? valueColumnDataType?.dataTypeId;
        let value = event?.target?.value ?? "";

        const matchedDataType = dataTypes?.find(dataType => dataType.id === dataTypeId);
        const dataTypeName = matchedDataType?.dataTypeName ?? "";

        const isInputValidDataType = validateDataType(value, dataTypeName);
        
        setIsDynamicListValues((prevState) => ({
            ...prevState,
            [input]: value,
            isDefaultValueCorrect: isInputValidDataType?.isValid
        }));

        setValueColumnDataType((prevState) => {
            return {
                ...prevState,
                dataType: isInputValidDataType?.dataTypeName,
                format: isInputValidDataType?.format,
                dataTypeId: dataTypeId
            }
        });
    }

    const onCheckUseTemplateConnection= (event) => {
        setState((prevState) => { return { ...prevState, useTemplateConnection: event?.target?.checked } });
    }

    return (
        <>
            {
                isDialogOpen ? (
                    <BasicInformationPopup
                        state={state}
                        prevStepRef={prevStepRef}
                        onChangeDataCollectionNameHandler={onChangeDataCollectionNameHandler}
                        onChangeGroupNameHandler={onChangeGroupNameHandler}
                        onChangeSubGroupNameHandler={onChangeSubGroupNameHandler}
                        //onSelectConnectionHandler={onSelectConnectionHandler}
                        // onSelectCategoryHandler={onSelectCategoryHandler}
                        onCheckCollectionAsConnection={onCheckCollectionAsConnection}
                        groups={groups}
                        onSelectGroupNameHandler={onSelectGroupNameHandler}
                        onSelectSubGroupNameHandler={onSelectSubGroupNameHandler}
                        subGroups={subGroups}
                        open={isDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        saveCollection={saveCollection}
                        isBasicInfoSubmitted={isBasicInfoSubmitted}
                        isUpdatingCollection={isUpdatingCollection}
                        isCloudLoading={isCloudLoading}
                        onToggleAllFieldsFilterable = {onToggleAllFieldsFilterable}
                        onToggleAllFieldsSortable = {onToggleAllFieldsSortable}
                    />
                ) : (
                    <BasicInformation
                        state={state}
                        prevStepRef={prevStepRef}
                        onChangeDataCollectionNameHandler={onChangeDataCollectionNameHandler}
                        onChangeGroupNameHandler={onChangeGroupNameHandler}
                        onChangeSubGroupNameHandler={onChangeSubGroupNameHandler}
                        onSelectConnectionHandler={onSelectConnectionHandler}
                        onChangeSourceNamePart2NameHandler={onChangeSourceNamePart2NameHandler}
                        // onSelectCategoryHandler={onSelectCategoryHandler}
                        onCheckCollectionAsConnection={onCheckCollectionAsConnection}
                        groups={groups}
                        onSelectGroupNameHandler={onSelectGroupNameHandler}
                        onSelectSubGroupNameHandler={onSelectSubGroupNameHandler}
                        subGroups={subGroups}
                        isDynamicListValues={isDynamicListValues}
                        handleOnChnageFilterInput={handleOnChnageFilterInput}
                        isVariableUsedInCollection={isVariableUsedInCollection}
                        onChangeColumnEndpointApiHandler = {onChangeColumnEndpointApiHandler}
                        onToggleFilterSupported = {onToggleFilterSupported}
                        onToggleSortSupported = {onToggleSortSupported}
                        onTogglePagingSupported = {onTogglePagingSupported}
                        onToggleAllFieldsFilterable = {onToggleAllFieldsFilterable}
                        onToggleAllFieldsSortable = {onToggleAllFieldsSortable}
                        isTemplateCompany = {isTemplateCompany}
                        isCloudStep={props?.isCloudStep}
                        selectedConnection={selectedConnection}
                        isTestedAfterSaveAppears={isTestedAfterSaveAppears}
                        isTestedData={isTestedData}
                        handleDefaultValueChange={handleDefaultValueChange}
                        valueColumnDataType={valueColumnDataType}
                        isValueColumnDataTypeBoolean={isValueColumnDataTypeBoolean}
                        onCheckUseTemplateConnection={onCheckUseTemplateConnection}
                    />
                )
            }
        </>
    );
}

export default BasicInformationController;
