import { useEffect, useRef, useState } from "react";
import SelectColumns from "../../../../components/data-collections/select-columns/select-columns";
import _ from 'lodash';
import { getTemplateCompany } from '../../../../services/variable-service'

import { getDataSourceByUID } from "../../../../services/data-source.service";
import MuiDialog from "../../../../shared/mui-dialog/mui-dialog";
import { getEquivalentPriorityDataType } from "./datatypes-equivalent-map";
import { formatDisplayName } from "../../utils/template-collection-parameter";

// collection permission head cells label
export const columnHeaderRowCells = [
    { id: 'columnName', numeric: false, disablePadding: true, label: 'Column Name / Path', isTemplateColunm: false },
    {id: 'groupName', numeric: false, disablePadding: false, label: "Group Name", isTemplateColunm: true},
    {id: 'subGroupName', numeric: false, disablePadding: false, label: "Subgroup Name", isTemplateColunm: true},
    { id: 'dataType', numeric: false, disablePadding: false, label: 'Data Type', isTemplateColunm: false },
    { id: 'displayFormat', numeric: false, disablePadding: false, label: 'Display Format', isTemplateColunm: false },
    { id: 'displayName', numeric: false, disablePadding: false, label: 'Display Name', isTemplateColunm: false },
    { id: 'isFilterable', numeric: false, disablePadding: false, label: 'Is Filterable', isTemplateColunm: true },
    { id: 'isSortable', numeric: false, disablePadding: false, label: 'Is Sortable', isTemplateColunm: true },
    { id: 'isDefault', numeric: false, disablePadding: false, label: 'Is Default', isTemplateColunm: true },
    { id: 'notQuerable', numeric: false, disablePadding: false, label: 'Not Querable', isTemplateColunm: true },
    { id: 'inactive', numeric: false, disablePadding: false, label: 'InActive', isTemplateColunm: true },
    // { id: 'edit', numeric: false, disablePadding: false, label: '', },
];

const SelectColumnsController = (props) => {

    const { state, setState, getColumnSchema, prevStepRef, getDisplayFormatList, collectionUId, isRest, setIsManualColumnValid, showSaveChangesButton } = props;

    const [isEditIndex, setIsEditIndex] = useState(-1);
    const searchInputRef = useRef(null);
    const [isTemplateCompany, setIsTemplateCompany] = useState(false);
    const [isTemplateCompanyState, setIsTemplateCompanyState] = useState(false);
    const [addNewColumnObject, setAddNewColumnObject] = useState({
        ColumnName: "",
        columnName: "",
        dataType: "",
        displayFormat: "",
        displayName: "",
        isManuallyCreated: true,
        isSelected: true,
        groupName: "",
        subGroupName: "",
        inActive: false,
    });
    const [deleteColumnName, setDeleteColumnName] = useState(null);
    const [displayFormatDataTypes, setDisplayFormatDataTypes] = useState([]);
    const [uncheckedColumns, setUncheckedColumns] = useState(0);

    useEffect(() => {
        if (state.displayFormatList?.length === 0) {
            getDisplayFormatList();
        }
    }, []);

    useEffect(() => {
        async function fetchTemplateCompany() {
            try {
                const response = await getTemplateCompany();
                if (response?.data == true) {
                    setIsTemplateCompanyState(true);
                }
            } catch (error) {
                console.error("Error fetching template company details:", error);
            }
        }

        fetchTemplateCompany();
    }, []);

    useEffect(() => {
        if (state.columnSchemaList && state.columnSchemaList?.length > 0) {
            validateManualColumns();
        }
    }, [state]);

    const validateManualColumns = () => {
        let uncheckColumnsCount = 0;
        state.columnSchemaList.map((col) => {
            if (col.isSelected === false) {
                uncheckColumnsCount += 1;
            }
        })
        setUncheckedColumns(uncheckColumnsCount);
        const manuallyCreatedColumns = state.columnSchemaList?.filter(col => col.isManuallyCreated);
        if (manuallyCreatedColumns?.length > 0) {

            const hasDuplicateColumnName = manuallyCreatedColumns.some(col => {
                const columnNameCount = state.columnSchemaList?.filter(item => item.columnName?.toLowerCase() === col.columnName?.toLowerCase()).length;
                return columnNameCount > 1;
            });

            const allFieldsValid = manuallyCreatedColumns?.every(col =>
                col.columnName && col.dataType && col.displayName && (col.displayFormat || col.DisplayFormat) && !hasDuplicateColumnName
            );
            setIsManualColumnValid(allFieldsValid);
        }
        else {
            setIsManualColumnValid(true)
        }
    };

    useEffect(() => {
        if (isTemplateCompanyState && props?.isRest) {
            setIsTemplateCompany(true);
        }
    }, [isTemplateCompanyState, props?.isRest]);

    useEffect(() => {
        if (state.displayFormatList?.length > 0) {
            // const dataTypes = getDisplayFormatDataTypes(state.displayFormatList);
            const prioritizeEquivalentDataTypes = getEquivalentPriorityDataType(state.displayFormatList);
            setDisplayFormatDataTypes(prioritizeEquivalentDataTypes);
        }
    }, [state.displayFormatList]);


    const editDisplayName = (event,index, column) => {
        let displayColumnName = formatDisplayName(event.target.value);

        let columnSchemaList = state.columnSchemaList;

        if (state.sortedColumnList?.length > 0) {
            index = getSelectedRowIndex(column);
        }

        if (column?.isEditColumnName) {
            let columnDetail = columnSchemaList[index];
            delete columnDetail.isEditColumnName;

            columnSchemaList[index] = columnDetail;
            let collectionColumns = state.collectionColumns;


            setColumnName(columnSchemaList,collectionColumns,displayColumnName,index,column);
        }
        else {
            columnSchemaList[index].isEditColumnName = true;


            setColumnName(columnSchemaList,state.collectionColumns,displayColumnName,index,column);
        }
        setState((prevState) => { return { ...prevState, columnSchemaList: columnSchemaList } });
        showSaveChangesButton(false);
    }

    const setColumnName = (
        columnSchemaList,
        collectionColumns,

        displayColumnName,
        index,
        column
    ) => {
        columnSchemaList[index].displayName = displayColumnName;

        let columnIndex = _.findIndex(collectionColumns, function (col) {
            return (
                (col?.columnName?.toLowerCase().trim() ||
                    col?.ColumnName?.toLowerCase().trim()) ===
                (column?.columnName?.toLowerCase().trim() ||
                    column?.ColumnName?.toLowerCase().trim())
            );
        });
        if (columnIndex !== -1) {
            collectionColumns[columnIndex].displayName = displayColumnName;
        }

        setState((prevState) => {
            return { ...prevState, collectionColumns: collectionColumns };
        });
    };

    // it displays the edit button on hovering for editing
    const showEditButtonOnHover = (isEditIndex) => {
        setIsEditIndex(isEditIndex);
    }

    // it hides the edit button
    const hideEditButtonOnMouseLeave = (index, column) => {
        setIsEditIndex(-1);
        if (column?.isEditColumnName)
            editDisplayName(index, column);
    }

    // gets the index of the selected row from columnSchemList 
    const getSelectedRowIndex = (column) => {
        let columnIndex = _.findIndex(state.columnSchemaList, function (col) {
            return (col?.ColumnName ? col?.ColumnName : col?.columnName)?.toLowerCase().trim() ===
                (column?.ColumnName ? column?.ColumnName : column?.columnName)?.toLowerCase().trim()
        });
        return columnIndex;
    }

    // it pushes the collection column object to column state, removes if row is unchecked
    const onCheckColumnHandler = (isChecked, index, column) => {
        let columnSchemaList = state.columnSchemaList;
        let collectionColumns = state.collectionColumns;

        if (state.sortedColumnList?.length > 0) {
            index = getSelectedRowIndex(column);
        }

        showSaveChangesButton(false);

        if (isChecked) {
            columnSchemaList[index].isSelected = true;

            let collectionColumn = {
                columnName: column.columnName ? column.columnName : column.ColumnName,
                displayFormat: column.displayFormat ? column.displayFormat : column.DisplayFormat,
                displayName: column?.displayName ? column?.displayName : column.DisplayFormat ? column.DisplayFormat : column.columnName ? column.columnName : column.ColumnName,
                dataType: column?.dataType ? column?.dataType : column?.DataType,
                ColumnDataType: column?.ColumnDataType ? column?.ColumnDataType : column?.columnDataType,
                collectionId: column?.collectionId ? column?.collectionId : 0,
                id: column?.id ? column?.id : 0,
                uId: column?.uId ? column?.uId : null,
                isSortable: column?.isSortable ? column?.isSortable : column?.IsSortable,
                isFilterable: column?.isFilterable ? column?.isFilterable : column?.IsFilterable,
                isDefault: column?.isDefault ? column?.isDefault : column?.IsDefault,
                notQuerable: column?.notQuerable ? column?.notQuerable : column?.notQuerable,
                groupName: column?.groupName ? column?.groupName : column?.GroupName,
                subGroupName: column?.subGroupName ? column?.subGroupName : column?.SubGroupName,
                isInactive: column?.isInactive ? column?.isInactive : column?.isInactive
            }

            collectionColumns.push(collectionColumn);

            setColumnSchema(collectionColumns, columnSchemaList);
        }
        else {
            columnSchemaList[index].isSelected = false;
            let updatedCollectionColumns = collectionColumns?.filter(col => (col?.ColumnName?.toLowerCase().trim() || col?.columnName?.toLowerCase().trim()) != (column?.ColumnName?.toLowerCase().trim() || column?.columnName?.toLowerCase().trim()));
            setColumnSchema(updatedCollectionColumns, columnSchemaList);
        }
    }

    // sets the latest column and format schema to the state
    const setColumnSchema = (collectionColumns, columnSchemaList) => {

        setState((prevState) => {
            return {
                ...prevState, collectionColumns: collectionColumns,
                columnSchemaList: columnSchemaList
            }
        });
    }

    // if all the columns are selected then it pushes all the columnList to the collectionColumns state, else removes
    // all the columns from the state 
    const onCheckAllColumnHandler = (event) => {

        let columnSchemaList = state.columnSchemaList;

        showSaveChangesButton(false);

        if (event?.target?.checked) {

            // creates a new array and deletes the specified property
            let columnFormatList = _.map(columnSchemaList, column => _.omit(column, ['isSelected']));

            let columnList = _.map(columnFormatList, column => {
                return { ...column, isSelected: true };
            });

            setColumnSchema(columnFormatList, columnList);
        }
        else {
            _.forEach(columnSchemaList, function (column) {
                _.set(column, 'isSelected', false);
            })
            let collectionColumns = state?.collectionColumns?.filter(i => i.isManuallyCreated);
            setColumnSchema(collectionColumns, columnSchemaList);
        }
    }

    // selects the display format for chosen column row 
    const onSelectDisplayFormatHandler = (index, displayFormat, column) => {

        if (state.sortedColumnList?.length > 0) {
            index = getSelectedRowIndex(column);
        }
        let selectedFormat = _.filter(state?.displayFormatList, function (i) {
            return i?.id === displayFormat;
        })[0]?.displayFormat;

        // set format to column list
        let columnSchemaList = state.columnSchemaList;
        // set format in selected columns list
        let collectionColumn = state.collectionColumns;

        if (collectionUId) {
            columnSchemaList[index].displayFormat = selectedFormat;
            _.forEach(collectionColumn, function (col) {
                if (col?.columnName?.toLowerCase().trim() === column?.columnName?.toLowerCase().trim())
                    _.set(col, 'displayFormat', selectedFormat);
            })
        }
        else {
            columnSchemaList[index].DisplayFormat = selectedFormat;
            _.forEach(collectionColumn, function (col) {
                if (col?.ColumnName?.toLowerCase().trim() === (column?.ColumnName?.toLowerCase().trim() || column?.columnName?.toLowerCase().trim()))
                    _.set(col, 'DisplayFormat', selectedFormat);
            })
        }
        setColumnSchema(collectionColumn, columnSchemaList);
        showSaveChangesButton(false);
    }

    // sorts the column array by searched key value
    const onChangeSearchColumnHandler = (searchedKey) => {
        let columnSchemaList = [];
        let sortedColumnList = [];

        if (searchedKey) {
            columnSchemaList = state.columnSchemaList;
            sortedColumnList = [];

            _.forEach(columnSchemaList, function (column) {
                if (_.includes((column?.ColumnName ? column?.ColumnName : column?.columnName)?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    sortedColumnList.push(column);
            })
        }
        else
            searchInputRef.current.value = '';

        setState((prevState) => {
            return {
                ...prevState,
                sortedColumnList: searchedKey ? sortedColumnList : []
            }
        });
        showSaveChangesButton(false);
    }
    const onCheckSortableHandler = (checked, index, column) => {
        const { columnSchemaList, collectionColumns } = state;

        // Update columnSchemaList
        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].isSortable = checked;

        // Update collectionColumns
        const updatedCollectionColumns = collectionColumns.map(col =>
            col.columnName === column.columnName ? { ...col, isSortable: checked } : col
        );

        // Set the updated state
        setState((prevState) => ({
            ...prevState,
            columnSchemaList: updatedColumnSchemaList,
            collectionColumns: updatedCollectionColumns,
        }));

        showSaveChangesButton(false);
    };

    const onCheckQuerableHandler = (checked, index, column) => {
        const { columnSchemaList, collectionColumns } = state;

        // Update columnSchemaList
        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].notQuerable = checked;

        // Update collectionColumns
        const updatedCollectionColumns = collectionColumns.map(col =>
            col.columnName === column.columnName ? { ...col, notQuerable: checked } : col
        );

        // Set the updated state
        setState((prevState) => ({
            ...prevState,
            columnSchemaList: updatedColumnSchemaList,
            collectionColumns: updatedCollectionColumns,
        }));

        showSaveChangesButton(false);
    }

    const onCheckFilterableHandler = (checked, index, column) => {
        const { columnSchemaList, collectionColumns } = state;

        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].isFilterable = checked;

        const updatedCollectionColumns = collectionColumns.map(col =>
            col.columnName === column.columnName ? { ...col, isFilterable: checked } : col
        );

        setState((prevState) => ({
            ...prevState,
            columnSchemaList: updatedColumnSchemaList,
            collectionColumns: updatedCollectionColumns,
        }));

        showSaveChangesButton(false);
    };

    const onCheckDefaultHandler = (checked, index, column) => {
        const { columnSchemaList, collectionColumns } = state;

        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].isDefault = checked;

        const updatedCollectionColumns = collectionColumns.map(col =>
            col.columnName === column.columnName ? { ...col, isDefault: checked } : col
        );

        setState((prevState) => ({
            ...prevState,
            columnSchemaList: updatedColumnSchemaList,
            collectionColumns: updatedCollectionColumns,
        }));

        showSaveChangesButton(false);
    };


    const onCheckInActiveHandler = (checked, index, column) => {
        const { columnSchemaList, collectionColumns } = state;

        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].inActive = checked;

        const updatedCollectionColumns = collectionColumns.map(col =>
            col.columnName === column.columnName ? { ...col, inActive: checked } : col
        );

        setState((prevState) => ({
            ...prevState,
            columnSchemaList: updatedColumnSchemaList,
            collectionColumns: updatedCollectionColumns,
        }));

        showSaveChangesButton(false);
    };

    const addNewColumn = () => {
        let columnSchemaList = [...state.columnSchemaList];
        let collectionColumns = [...state.collectionColumns];

        columnSchemaList.push(addNewColumnObject);
        collectionColumns.push(addNewColumnObject);

        setColumnSchema(collectionColumns, columnSchemaList);

        setAddNewColumnObject({
            ColumnName: "",
            columnName: "",
            dataType: "",
            displayFormat: "",
            displayName: "",
            isManuallyCreated: true,
            isSelected: true,
            groupName: "",
            subGroupName: "",
            inActive: false,
        });
        showSaveChangesButton(false);
        searchInputRef.current.value = "";
    }

    const editManualColumnName = (event, index, column) => {
        var columName = event?.target?.value;
        let columnSchemaList = state.columnSchemaList;

        const hasDuplicateColumnName = state.columnSchemaList?.filter(item => item.columnName?.toLowerCase() === columName?.toLowerCase())?.length;

        if (hasDuplicateColumnName > 0) {
            setState((prevState) => { return { ...prevState, columnSchemaError: "There is already a Column Name Present." } });
        }
        else {
            setState((prevState) => { return { ...prevState, columnSchemaError: null } });
        }

        if (state.sortedColumnList?.length > 0) {
            index = getSelectedRowIndex(column);
        }

        if (column?.isEditColumnName) {
            let columnDetail = columnSchemaList[index];
            delete columnDetail.isEditColumnName;

            columnSchemaList[index] = columnDetail;
            let collectionColumns = state.collectionColumns;

            setManualColumnName(columnSchemaList, collectionColumns, columName, index, column);
        }
        else {
            columnSchemaList[index].isEditColumnName = true;

            setManualColumnName(columnSchemaList, state.collectionColumns, columName, index, column);
        }
        setState((prevState) => { return { ...prevState, columnSchemaList: columnSchemaList } });
    }

    const setManualColumnName = (
        columnSchemaList,
        collectionColumns,
        columnName,
        index,
        column
    ) => {
        columnSchemaList[index].columnName = columnName;
        if (index !== -1) {

            if (columnSchemaList.length !== collectionColumns.length) {
                index = index - uncheckedColumns;
            }

            collectionColumns[index].columnName = columnName;
        }

        setState((prevState) => {
            return { ...prevState, collectionColumns: collectionColumns };
        });
    };

    // const getDisplayFormatDataTypes = (displayFormatList) => {
    //     const uniqueMap = displayFormatList.reduce((acc, item) => {
    //         if (!acc.has(item.sysDataTypeId)) {
    //             acc.set(item.sysDataTypeId, item);
    //         }
    //         return acc;
    //     }, new Map());

    //     return Array.from(uniqueMap.values());
    // };

    const onSelectDataTypeHandler = (index, dataType, column) => {

        if (state.sortedColumnList?.length > 0) {
            index = getSelectedRowIndex(column);
        }
        const columnDataType = displayFormatDataTypes?.find(i => i.sysDataTypeId === dataType)?.dataBaseDataType;
        let datatypeDisplayFormate = state?.displayFormatList.filter(i => i.sysDataTypeId == dataType)?.[0]?.displayFormat;
        let columnSchemaList = state.columnSchemaList;
        let collectionColumn = state.collectionColumns;

        if (collectionUId) {
            columnSchemaList[index].dataType = dataType;
            columnSchemaList[index].ColumnDataType = columnDataType;
            columnSchemaList[index].dataTypeName = columnDataType;
            columnSchemaList[index].displayFormat = datatypeDisplayFormate;
            if (column?.isManuallyCreated) {
                collectionColumn[index - uncheckedColumns].displayFormat = "";
            }
            _.forEach(collectionColumn, function (col) {
                if (col?.columnName?.toLowerCase().trim() === column?.columnName?.toLowerCase().trim()) {
                    _.set(col, 'dataType', dataType);
                    _.set(col, 'dataTypeName', columnDataType);
                    _.set(col, 'displayFormat', datatypeDisplayFormate);
                }
            })
        }
        else {
            columnSchemaList[index].dataType = dataType;
            columnSchemaList[index].ColumnDataType = columnDataType;
            columnSchemaList[index].DataTypeName = columnDataType;
            columnSchemaList[index].DisplayFormat = datatypeDisplayFormate;
            if (column?.isManuallyCreated) {
                collectionColumn[index - uncheckedColumns].DisplayFormat = "";
            }
            _.forEach(collectionColumn, function (col) {
                if (col?.ColumnName?.toLowerCase().trim() === (column?.ColumnName?.toLowerCase().trim() || column?.columnName?.toLowerCase().trim())) {
                    _.set(col, 'DataType', dataType);
                    _.set(col, 'DisplayFormat', datatypeDisplayFormate);
                }
            })
        }
        setColumnSchema(collectionColumn, columnSchemaList);

        showSaveChangesButton(false);
    }

    const deleteManualColumn = (index, column) => {
        if (column?.columnName == "") {
            let columnSchemaList = [...state.columnSchemaList];
            let collectionColumns = [...state.collectionColumns];
            columnSchemaList.splice(index, 1);
            collectionColumns.splice(index - uncheckedColumns, 1);

            setColumnSchema(collectionColumns, columnSchemaList);
            return;
        }
        setState((prevState) => {
            return {
                ...prevState, isShowDeletePopUp: true
            }
        });

        if (state.isShowDeletePopUp) {
            let columnSchemaList = [...state.columnSchemaList];
            let collectionColumns = [...state.collectionColumns];

            const targetIndex = (deleteColumnName == null) ? index : columnSchemaList.findIndex(row => row.columnName === deleteColumnName);
            columnSchemaList.splice(targetIndex, 1);
            collectionColumns.splice(targetIndex - uncheckedColumns, 1);

            setColumnSchema(collectionColumns, columnSchemaList);
            if (searchInputRef?.current?.value) {
                let sortedColumnList = [...state.sortedColumnList];
                const targetIndex = sortedColumnList.findIndex(row => row.columnName === deleteColumnName);
                sortedColumnList.splice(targetIndex, 1);

                setState((prevState) => {
                    return {
                        ...prevState,
                        sortedColumnList: sortedColumnList
                    }
                });
            }
            closeDialog();
        }
        setDeleteColumnName(column?.columnName || null);

        showSaveChangesButton(false);
    };

    const closeDialog = () => {
        setState((prevState) => {
            return {
                ...prevState, isShowDeletePopUp: false
            }
        });
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination || source.index === destination.index) return;

        const reorderedColumns = state?.columnSchemaList;
        const [movedColumn] = reorderedColumns?.splice(source.index, 1);
        reorderedColumns.splice(destination.index, 0, movedColumn);

        const orderedColumns = reorderedColumns?.filter(i => i.isSelected);
        setState((prevState) => {
            return {
                ...prevState, collectionColumns: orderedColumns
            }
        });
    };

    const editGroupName = (event,index, column) => {
        const {columnSchemaList, collectionColumns} = state;

        const newGroupName = event.target.value;
        
        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].groupName = newGroupName;

        const updatedCollectionColumns = collectionColumns.map(col => 
            col.columnName === column.columnName ? {...col, groupName: newGroupName} : col
        );

        setState((prevState) => {
            return {
                ...prevState, 
                columnSchemaList: updatedColumnSchemaList, 
                collectionColumns: updatedCollectionColumns
            }
        });

        showSaveChangesButton(false);
    }

    const editSubGroupName = (event,index, column) => {
        const {columnSchemaList, collectionColumns} = state;

        const newSubGroupName = event.target.value;
        
        const updatedColumnSchemaList = [...columnSchemaList];
        updatedColumnSchemaList[index].subGroupName = newSubGroupName;

        const updatedCollectionColumns = collectionColumns.map(col => 
            col.columnName === column.columnName ? {...col, subGroupName: newSubGroupName} : col
        );

        setState((prevState) => {
            return {
                ...prevState, 
                columnSchemaList: updatedColumnSchemaList, 
                collectionColumns: updatedCollectionColumns
            }
        });

        showSaveChangesButton(false);
    }

    return (
        <>
            <MuiDialog
                isShowPopUp={state.isShowDeletePopUp}
                secondaryButtonAction={closeDialog}
                primaryButtonAction={deleteManualColumn}
                closeDialog={closeDialog}
                secondaryButtonTitle="No"
                primaryButtonTitle="Yes"
                titleDescription="Are you sure you want to delete the column?"
            />
            <SelectColumns
                prevStepRef={prevStepRef}
                editDisplayName={editDisplayName}
                onCheckColumnHandler={onCheckColumnHandler}
                onCheckAllColumnHandler={onCheckAllColumnHandler}
                showEditButtonOnHover={showEditButtonOnHover}
                hideEditButtonOnMouseLeave={hideEditButtonOnMouseLeave}
                onSelectDisplayFormatHandler={onSelectDisplayFormatHandler}
                onChangeSearchColumnHandler={onChangeSearchColumnHandler}
                isEditIndex={isEditIndex}
                searchInputRef={searchInputRef}
                state={state}
                onCheckSortableHandler={onCheckSortableHandler}
                onCheckFilterableHandler={onCheckFilterableHandler}
                onCheckDefaultHandler={onCheckDefaultHandler}
                isTemplateCompany={isTemplateCompany}
                addNewColumn={addNewColumn}
                deleteManualColumn={deleteManualColumn}
                editManualColumnName={editManualColumnName}
                displayFormatDataTypes={displayFormatDataTypes}
                onSelectDataTypeHandler={onSelectDataTypeHandler}
                isRest={isRest}
                onCheckQuerableHandler={onCheckQuerableHandler}
                closeDialog={closeDialog}
                onDragEnd={onDragEnd}
                onCheckInActiveHandler={onCheckInActiveHandler}
                editGroupName={editGroupName}
                editSubGroupName={editSubGroupName}
            />
        </>
    );
}

export default SelectColumnsController;
